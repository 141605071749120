(function (module) {

    var travelAvailabilityCtrl = function ($scope, $uibModal, volunteerSvc, travelAvailabilitySvc, helperSvc, componentHelperSvc, volunteerHelperSvc, volunteerDataAccessTypes) {
        var model = this;
        var volunteerDataAccessType = volunteerDataAccessTypes.VOLUNTEERANDADMIN;

        model.parent = $scope.$parent.model;
        model.currentYear = travelAvailabilitySvc.currentYear;
        model.title = 'On-site Visit & Safety';
        model.allowEdit = volunteerHelperSvc.allowEdit(volunteerDataAccessType);
        model.formatDate = helperSvc.formatDate;

        componentHelperSvc.invokeOnRendered('travelAvailability', $scope);

        model.getGenericAvailabilityString = function (availability, isTravel) {

            let str = '';

            switch (availability) {
                case 1:
                    str += "Yes, both US and non-US" + (isTravel ? " travel" : " virtual visits")
                    break;
                case 2:
                    str += "Yes, US" + (isTravel ? " travel only" : " virtual visits only")
                    break;
                case 3:
                    str += "Yes, non-US" + (isTravel ? " travel only" : " virtual visits only")
                    break;
                case 4:
                    str += "No"
                    break;
                default:
                    return "Not Entered";
            }

            return str;
        };

        model.getTravelAvailabilityString = function (availability) {
            return model.getGenericAvailabilityString(availability, true)
        };

        model.getVirtualAvailabilityString = function (availability) {
            return model.getGenericAvailabilityString(availability, false)
        };

        model.editTravelAvailability = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/travelAvailabilityEdit.html',
                size: 'md',
                controller: 'travelAvailabilityEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
        model.data = travelAvailabilitySvc.data;
    };

    module.controller('travelAvailabilityCtrl', travelAvailabilityCtrl);

}(angular.module('volunteer')));