(function (module) {

    var statementSendCtrl = function ($scope,$state, $filter, $uibModal, barSvc, helperSvc, reviewTypes, statementTypeNames, statementTypeNamesById, statementStatuses, codeSvc, statementTypeLabel, programReviewFinalActionSvc,
        statementAdminSvc, $q, statementTypeIds, statementStatusNames, dueResponseTypeIds, commissionTypes, statementSvc, commissionAbbreviations, reviewTypeIds, statementStorageSvc, alertSvc,
        currentUserHelper, oauth, documentSvc, documentTypes) {

        var model = this;

        model.title = "Find a Statement";
        model.resultsTitle = "Results";
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.showSpinner = false;
        model.showSpinnerSearch = false;
        model.firstLoad = true;
        model.statements = [];
        model.searchOptions = {};
        model.showSpinnerExport = false;
        model.formatDate = helperSvc.formatDate;
        model.getNextDueDateText = statementSvc.getNextDueDateText;
        model.getYearSpan = helperSvc.getYearSpan;
        model.statementsSelected = [];
        model.commissionAbbreviations = commissionAbbreviations
        model.reviewTypeIds = reviewTypeIds;
        model.statementStatusConstant = statementStatuses;
        model.statementTypeLabel = angular.copy(statementTypeLabel);

        model.canDownload = oauth.isAdmin(); // currentUserHelper.isITStaff();

        var statusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'statementStatuses',
            svcCallback: codeSvc.getStatementStatuses,
            helperCallback: helperSvc.getResults
        };

        var reviewTypesDataSource = {
            dataHolder: model,
            dataLocationName: 'reviewTypes',
            svcCallback: codeSvc.getReviewTypes,
            helperCallback: helperSvc.getResults
        };

        var statementTypesDataSource = {
            dataHolder: model,
            dataLocationName: 'statementTypes',
            svcCallback: codeSvc.getStatementTypes,
            helperCallback: helperSvc.getResults
        }

        barSvc.listenForShow(function () {
            if (model.firstLoad) {
                model.showSpinnerSearch = true;
            } else {
                model.showSpinner = true;
            }
        });

        barSvc.listenForHide(function () {
            if (model.firstLoad) {
                model.showSpinnerSearch = false;
                model.firstLoad = false;
            } else {
                model.showSpinner = false;
            }
        });

        model.reset = function () {
            reset();
        };

        model.toggleAllDisabled = function(){
            if(parseInt(model.searchOptions.statusId) !== model.statementStatusConstant.ADMINPREPFORSEND)
                return true;
            return false;
        }

        model.retrieve = function () {
            var searchOpts = model.searchOptions;

            model.resultMsg = null;
            barSvc.showSpinner();

            statementAdminSvc.advancedSearch(searchOpts).then(function (data) {
                barSvc.hideSpinner();
                //for the checkboxes
                angular.forEach(data, function (statement) {
                    statement.selected = false;
                    statement.dueResponses = formatResponseData(statement.dueResponses);
                    statement.draftStatementSent = angular.fromJson(statement.draftStatementSent)
                    statement.finalStatementSent = angular.fromJson(statement.finalStatementSent)
                })

                model.statements = data;
                model.statementsSelected = [];

                if (model.statements.length === 0) {
                    model.resultMsg = "No results found";
                }
            });
        };

        model.hasResponses = function (dueResponses) {
            return dueResponses.sevenDay || dueResponses.interimStatus || dueResponses.thirtyDay || dueResponses.postThirtyDay;
        };

        function formatResponseData(dueResponseJson){
            var dueResponses = angular.fromJson(dueResponseJson);
            var formattedDueResponses = {sevenDay: null, interimStatus: null, thirtyDay: null, postThirtyDay: null};
            if(dueResponses){
                for(var i = 0; i < dueResponses.length; i++){
                    var dueResponse = dueResponses[i];
                    if(dueResponse.dueResponseTypeId === dueResponseTypeIds.SEVENDAY)
                        formattedDueResponses.sevenDay = dueResponse;
                    if(dueResponse.dueResponseTypeId === dueResponseTypeIds.INTERIM)
                        formattedDueResponses.interimStatus = dueResponse;
                    if(dueResponse.dueResponseTypeId === dueResponseTypeIds.THIRTYDAY)
                        formattedDueResponses.thirtyDay = dueResponse;
                    if(dueResponse.dueResponseTypeId === dueResponseTypeIds.THIRTYDAYPOST)
                        formattedDueResponses.postThirtyDay = dueResponse;
                }
            }
            return formattedDueResponses;
        }

        model.openFinalAction = function () {
            $state.go('statementAdmin.finalAction');
        };

        model.csvHeaders = ['Short Name', 'Institution', 'Statement Type', 'Review Type', 'Review Year', 'Statement Status', 'Visit End Date', 'Commission Name', 'Joint Review', 'Simultaneous Review', 'TC', 'TC Submit Date', 'Co-TC', 'ED1', 'ED1 Submit Date', 'ED2', 'ED2 Submit Date', 'ADJ', 'ADJ Submit Date', 'Due Response Submitted', 'Due Response Date', 'All Programs Cancelled' ];
        model.csvColumns = ['abbreviatedName', 'institutionName', 'statementType', 'reviewType', 'reviewYear', 'statementStatus', 'visitEndDate', 'commissionName', 'reviewJointOption', 'reviewSimultaneousOption', 'tc', 'tcSubmitDate', 'cotc', 'eD1', 'eD1SubmitDate', 'eD2', 'eD2SubmitDate', 'adj', 'adjSubmitDate', 'dueResponseSubmitted', 'dueResponseDate', 'allProgramsCancelledOrWithdrawn' ];
        model.exportCSV = function () {
            var defer = $q.defer();
            model.showSpinnerExport = true;

            statementAdminSvc.advancedSearch(model.searchOptions, true).then(function(data){
                var exportingStatements = data;

                var convertedStatementList = [];

                angular.forEach(exportingStatements, function (statement) {
                    if ((!statement.statementStatusId && statement.reviewType === 'IR') ||
                        (statement.reviewType !== 'IR' && statement.visitEndDate && statement.visitEndDate < new Date())) {
                            statement.statementStatusId = 1;
                    }
                    
                    var tempStatement = {
                        institutionName: statement.sortName,
                        abbreviatedName: statement.abbreviatedName,
                        statementType: statementTypeNamesById[statement.statementTypeId],
                        reviewType: reviewTypes[statement.reviewType],
                        reviewYear: helperSvc.getYearSpan(statement.reviewYear).replace(/\u200E/g, ''),
                        statementStatus: (statement.statementStatusId) ? statementStatusNames[statement.statementStatusId] : '',
                        visitEndDate: statement.visitEndDate ? helperSvc.formatDate(statement.visitEndDate,null,true).toLocaleDateString().replace(/\u200E/g, '') : '',
                        commissionName: statement.commissionName,
                        reviewJointOption : statement.reviewJointOption ? 'Yes' : 'No',
                        reviewSimultaneousOption : statement.reviewSimultaneousOption ? 'Yes' : 'No',
                        tc: statement.tc,
                        tcSubmitDate: statement.tcSubmitDate ? helperSvc.formatDate(statement.tcSubmitDate).toLocaleDateString().replace(/\u200E/g, '') : '',
                        cotc : statement.cotc,
                        eD1: statement.eD1,
                        eD1SubmitDate: statement.eD1SubmitDate ? helperSvc.formatDate(statement.eD1SubmitDate).toLocaleDateString().replace(/\u200E/g, '') : '',
                        eD2: statement.eD2,
                        eD2SubmitDate: statement.eD2SubmitDate ? helperSvc.formatDate(statement.eD2SubmitDate).toLocaleDateString().replace(/\u200E/g, '') : '',
                        adj: statement.adj,
                        adjSubmitDate: statement.adjSubmitDate ? helperSvc.formatDate(statement.adjSubmitDate).toLocaleDateString().replace(/\u200E/g, '') : '',
                        dueResponseSubmitted: statement.statementStatusId >= model.statementStatusConstant.SENTTOINSTITUTION ? (statement.dueResponseSubmitted ? 'Yes' : 'No') : '',
                        dueResponseDate: statement.dueResponseDate ? helperSvc.formatDate(statement.dueResponseDate).toLocaleDateString().replace(/\u200E/g, '') : '',
                        allProgramsCancelledOrWithdrawn: statement.allProgramsCancelledOrWithdrawn ? 'Cancelled' : ''

                    };

                    convertedStatementList.push(tempStatement);
                });

                convertedStatementList = $filter('orderBy')(convertedStatementList, 'sortName');
                defer.resolve(resolveExport(convertedStatementList));

            });

            return defer.promise;
        };

         function resolveExport(data) {
            model.showSpinnerExport = false;
            return data;
        }

        model.downloadAgendaBook = function () {
            var searchOpts = getDefaultSearchOptions();
            searchOpts.commissions = model.searchOptions.commissions.length ? model.searchOptions.commissions : commissionTypes;
            var commissionsList = searchOpts.commissions.length == 2 ?
                searchOpts.commissions[0].abrv + ' and ' + searchOpts.commissions[1].abrv :
                searchOpts.commissions
                    .map(function (comm, index, arr) {
                        var commName = (arr.length > 1 && arr.length - 1 == index ? 'and ' : '');
                        commName += comm.abrv;
                        return commName;
                    })
                    .join(', ');

            var msg = "You are about to download all statements and PAFs from the current review year for " + commissionsList +
                ". This may take several minutes.  Do you want to proceed?";

            alertSvc.confirm(msg, doDownloadAgendaBook);

            function doDownloadAgendaBook() {
                barSvc.showSpinner();
                model.isDownloadingAgendaBook = true;
                statementAdminSvc.advancedSearch(searchOpts, false, true).then(function (data) {
                    var statements = data.filter(function (statement) {
                        return model.isStatementStarted(statement);
                    });

                    statementAdminSvc.downloadAgendaBook(statements).then(
                        function () {
                            model.isDownloadingAgendaBook = false;
                            barSvc.hideSpinner();
                            alertSvc.addAlertSuccess("Statements successfully downloaded.");
                        },
                        function (error) {
                            console.log(error);
                            model.isDownloadingAgendaBook = false;
                            barSvc.hideSpinner();
                            alertSvc.addAlertWarning('Statements could not be downloaded at this time.');
                        }
                    );
                });
            }
        }

        model.downloadActionSummaries = function () {
            var searchOpts = getDefaultSearchOptions();
            searchOpts.commissions = model.searchOptions.commissions.length ? model.searchOptions.commissions : commissionTypes;
            var commissionsList = searchOpts.commissions.length == 2 ?
                searchOpts.commissions[0].abrv + ' and ' + searchOpts.commissions[1].abrv :
                searchOpts.commissions
                    .map(function (comm, index, arr) {
                        var commName = (arr.length > 1 && arr.length - 1 == index ? 'and ' : '');
                        commName += comm.abrv;
                        return commName;
                    })
                    .join(', ');

            var msg = "You are about to download all accreditation action summaries from the current review year for " + commissionsList +
                ". This may take several minutes.  Do you want to proceed?";

            alertSvc.confirm(msg, doDownloadActionsSummary);

            function doDownloadActionsSummary() {
                barSvc.showSpinner();
                model.isDownloadingActionSummaries = true;
                statementAdminSvc.advancedSearch(searchOpts, false, true).then(function (data) {
                    var statements = data.filter(function (statement) {
                        return model.isFinalStatement(statement);
                    });
                    statementAdminSvc.downloadActionSummaries(statements).then(
                        function () {
                            model.isDownloadingActionSummaries = false;
                            barSvc.hideSpinner();
                            alertSvc.addAlertSuccess("Accreditation action summary report successfully downloaded.");
                        },
                        function (error) {
                            console.log(error);
                            model.isDownloadingActionSummaries = false;
                            barSvc.hideSpinner();
                            alertSvc.addAlertWarning('"Accreditation action summary report could not be downloaded at this time.');
                        }
                    );
                });
            }
        }

        model.openStatementTool = function (statement) {
            if(statement.statementStatusId === model.statementStatusConstant.SENTTOINSTITUTION || statement.statementStatusId === model.statementStatusConstant.RESENTTOINSTITUTION ){
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/statement/templates/modals/adminEditConfirm.html',
                    size: 'md',
                    controller: 'adminEditConfirmCtrl',
                    resolve: {
                        statement: function () { return statement; }
                    }
                });
            }else{
                var url = $state.href('statementTool', { statementId: statement.statementId });
                window.open(url, '_blank');
            }
        }

        model.openDraftReadOnly = function (statement) {
            var drafts = angular.fromJson(statement.draftStatementSent)
           
            var draftStatementId = drafts[0].statementId
            $state.go('statementTool', { statementId: draftStatementId });         
        }

        model.openStatementTracking = function (statement) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/statementTracking.html',
                size: 'lg',
                controller: 'statementTrackingCtrl',
                resolve: {
                    reviewTeamId: function () { return statement.reviewTeamId; }
                }
            });
            
        }

        model.openReviewDetail = function (statement) {
            $state.go('reviews.detail', { organizationId: statement.organizationId, reviewTeamId: statement.reviewTeamId });         
        };

        model.getStatementStatus = function (statement) {
           if (statement.statementStatusId){
                if(statement.statementTypeId === statementTypeIds.DRAFT){
                    return statementStatusNames[statement.statementStatusId]
                }
                else if (statement.statementTypeId === statementTypeIds.FINAL) {
                    if (statement.statementStatusId === statementStatuses.ADMINPREPFORSEND) {
                        return 'Admin Received Draft Final';
                    }
                    return statementStatusNames[statement.statementStatusId];
                }
                else if (statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY) {
                    if (statement.statementStatusId === statementStatuses.ADMINPREPFORSEND) {
                        return 'Admin Received Post-30 Day Statement';
                    }
                    return statementStatusNames[statement.statementStatusId];
                }
                else if (statement.statementTypeId === statementTypeIds.COMMISSIONEDITING) {                
                    return statementStatusNames[statement.statementStatusId];
                }
            } else {
                return 'Statement Not Started';
            }
        };

        model.getStatementTypeNames = statementAdminSvc.getStatementTypeName;

        model.isStatementStarted = function (statement) {
            return statement.statementStatusId;
        };

        model.isFinalStatement = function (statement) {
            return [statementTypeIds.FINAL, statementTypeIds.POSTTHIRTYDAY, statementTypeIds.COMMISSIONEDITING].indexOf(statement.statementTypeId) > -1;
        }

        model.getSubmittedDate = function(statement){

            if(statement.reviewType === reviewTypeIds.INTERIMREPORT){
                var irDate = new Date(statement.reviewYear-1, 9, 15).toLocaleDateString("en-US");
                return irDate;
            }
            else
                return statement.visitEndDate;
            
            
            return null;
        }

        model.toggleAll = function () {
            var id = "toggleAll";
            var checkBox = document.getElementById(id);

            if (checkBox.checked)
                model.statementsSelected = angular.copy(model.statements);
            else {
                model.statementsSelected = [];
            }   

            angular.forEach(model.statements, function (item) {
                item.selected = checkBox.checked;
            });
        
        }

        model.toggleStatementSelection = function (statement) {
            var id = "statementId-" + statement.statementId;
            var checkBox = document.getElementById(id);

            if (checkBox.checked)
                model.statementsSelected.push(statement);
            else {
                var index = helperSvc.getFirstMatch(model.statementsSelected, 'statementId', statement.statementId)
                model.statementsSelected.splice(index, 1);
            }   
        }

        model.sendDraftStatements = function () {
            sendStatements(statementAdminSvc.createDraftStatementDocuments, statementAdminSvc.sendDraftStatements);
        }
 

        model.sendFinalStatements = function () {
            sendStatements(statementAdminSvc.createFinalStatementDocuments, statementAdminSvc.sendFinalStatements);
        }

        function handleSvcError(error) {
            console.log(error);
            model.isSending = false;
            barSvc.hideSpinner();
            alertSvc.addAlertWarning('An error has occurred. Some statements could not be sent.');
        }

        function sendStatements(createStatementDocumentsFunc, sendStatementsFunc) {
            barSvc.showSpinner();
            model.isSending = true;
            var statementIds = model.statementsSelected.filter(function (s) { return s.statementId; }).map(function (s) { return s.statementId; });
            try {
                createStatementDocumentsFunc(model.statementsSelected).then(
                    function (data) {
                        try {
                            sendStatementsFunc(statementIds).then(
                                function () {
                                    model.retrieve();
                                    model.isSending = false;
                                    barSvc.hideSpinner();
                                    alertSvc.addAlertSuccess("Statements successfully sent.");
                                },
                                handleSvcError
                            );
                        } catch (error) {
                            handleSvcError(error);
                        }
                    },
                    handleSvcError
                );
            } catch (error) {
                handleSvcError(error);
            }
        }

        model.getDraftSentDate = function (statement) {
            if (statement.draftStatementSent) {
                return statement.draftStatementSent && statement.draftStatementSent.length > 0 ? helperSvc.formatDate(statement.draftStatementSent[0].submittedTimestamp) : 'No Draft Sent';
            } else 
                return 'No Draft Sent'
        }
        model.getStatementDocument = function (statement) {
            var jsonArrDoc = angular.fromJson(statement.statementDocuments)
            var docs = jsonArrDoc.filter(t => t.documentTypeId === 7);

            return docs[0];  //only one file
        }

        model.openStatementPreview = function (reviewTeam) {
            statementStorageSvc.getAllStatementToolData(reviewTeam.statementId).then(function () {
                var statement = statementStorageSvc.data.statement;
                var finalSubmitted = statementStorageSvc.isFinalStatementSubmittedToInstitution(statement);
                var finalPendingSubmission = statement.statementStatusId == statementStatuses.ADMINPREPFORSEND;

                var loadModalInstance = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/statement/templates/modals/viewStatementPreview.html',
                        size: 'xl',
                        controller: 'viewStatementPreviewCtrl',
                        resolve: {
                            currentStatement: function () { return statement; }
                        }
                    });
                }

                if (finalSubmitted || finalPendingSubmission) {
                    var programsDataSource = {
                        dataHolder: statementStorageSvc,
                        dataLocationName: 'data.programs',
                        svcCallback: programReviewFinalActionSvc.getReviewsByReviewTeamId,
                        svcCallbackArguments: [statement.reviewTeamId],
                        odataResource: true
                    }

                    helperSvc.getData([programsDataSource]).then(function () {
                        loadModalInstance();
                    });
                } else {
                    loadModalInstance();
                }

            });         
        };

        model.uploadDocuments = function (statement) {
            var templateRoot = '/apps/common/templates/documents/';
            //var uploadStatement = statement;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: templateRoot + 'documentFilesUpload.html',
                size: 'md',
                controller: 'documentFilesUploadCtrl',
                resolve: {
                    referenceId: statement.statementId,
                    documentTypeId: 7 //executive letter
                }
            });
            
            modalInstance.result.then(function (data) {
                var ret_stream_id = data.stream_id;

                documentSvc.getDocumentNameByStreamId(ret_stream_id).then(function (name) {
                    var docName = name.value;
                    var docArr = [];
                    var statementDoc = { documentTypeId: 7, documentTitle: docName, fileName: docName, stream_id: ret_stream_id };
                    docArr.push(statementDoc);
                    statement.statementDocuments = docArr;
                });
            });
        }

        model.deleteDocument = function (statement) {
            var doc = model.getStatementDocument(statement);
            alertSvc.confirmDelete(doc.fileName, deleteFunc, doc);

            function deleteFunc() {
                documentSvc.deleteDocument(doc.stream_id, documentTypes.STATEMENT_MESSAGE_EXECUTIVE_LETTER.documentTypeId).then(
                    function () {
                        alertSvc.addAlertSuccess("Document successfully deleted.");
                        statement.statementDocuments = null;
                    },
                    function () { alertSvc.addAlertWarning("Document could not be deleted at this time."); }
                );
            }
        };

        function reset() {
            model.searchOptions = getDefaultSearchOptions();
            model.resultMsg = model.defaultSearchMsg;
            model.statements = [];
            model.statementsSelected = [];
        }

        function getDefaultSearchOptions() {
            return {
                reviewYear: model.currentReviewYear,
                statusId: null,
                statementTypeId: null,
                organizationName: null,
                reviewType: null,
                location: null,
                commissions: []
            }
        }

        function getYear() {
            //get years from start year to current year
            var STARTYEAR = 2017;
            var nextYear = new Date().getFullYear() +1;

            var numberOfYearsToAdd = (nextYear - STARTYEAR) + 1;
            var years = [];

            for (var i = numberOfYearsToAdd; i > 0; i--) {
                years.push(nextYear - (numberOfYearsToAdd - i));
            }

            return years;
        }

        var activate = function () {
            model.reviewYears = getYear();
            model.commissions = commissionTypes;
            model.statementStatusNames = statementStatusNames;
            var years = getYear();
            model.currentReviewYear = helperSvc.getAcademicYear();
            reset();

            barSvc.showSpinner();

            var dataSourceArray = [statusOptionsDataSource, reviewTypesDataSource, statementTypesDataSource];

            helperSvc.getData(dataSourceArray).then(function () {
                barSvc.hideSpinner();
                model.retrieve();
            });
        }();
    };

    module.controller('statementSendCtrl', statementSendCtrl);

}(angular.module('statement')));