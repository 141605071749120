(function (module) {
    var statementVolunteerCtrl = function ($scope, $q, $state, $stateParams, $uibModal, $window, helperSvc, alertSvc, statementSvc,
        statementStorageSvc, localStorage, programReviewSvc, codeSvc, readinessSvc, statementStatuses, reviewTypeIds,
        programReviewFinalActionSvc, statementTypeIds, criteriaTypes, oauth, userReviewSvc, messageSvc,
        teamMemberTypeNames, programReviewTypeIds, commissionIds, teamMemberTypeIds, currentUser, evaluatorReportSvc, statementValidationSvc,
        statementFindingTypes, messageTemplateTypes, reviewTeamMemberSvc, personEmailSvc, dueResponseSvc, eventSvc,
        programReviewActionCodes, programReviewActionNames, statementTypeLabel, statementTypeNamesById, programAuditSvc, programAuditAccessSvc,
        programAuditInstitutionalSummarySvc
    ) {
        var model = this;
        var selectedId = null;

        model.saving = { isSaving: false };

        //sets up the tab syncing for data
        $window.addEventListener("storage", message_receive);

        $scope.$on("$destroy", function () {
            $window.removeEventListener("storage", message_receive);
        });

        function message_receive(ev) {
            if (ev.key && ev.newValue && (ev.key.indexOf('statementData') > -1 || ev.key.indexOf('evaluatorReportData') > -1 || ev.key.indexOf('dueResponseData') > -1 || ev.key.indexOf('saving') > -1)) {
                // Make sure event is intended for this message handler
                var object = angular.fromJson(ev.newValue);
                var eventReviewTeamId = object ? (object.reviewTeamId || null) : null;
                if (eventReviewTeamId != model.reviewTeamId) {
                    return;
                }

                if (ev.key.indexOf('saving') > -1) {
                    $scope.$evalAsync(function () {
                        model.saving.isSaving = angular.fromJson(ev.newValue).value;
                    });
                    return;
                }

                var statementPromise = statementStorageSvc.getByReviewTeamId(model.reviewTeamId);
                var evaluatorReportPromise = evaluatorReportSvc.getEvaluatorReportByReviewTeamId(model.reviewTeamId);
                var dueResponsePromise = dueResponseSvc.getDueResponsesByReviewTeamId(model.reviewTeamId);

                $q.all([statementPromise, evaluatorReportPromise]).then(function () {
                    model.data = statementStorageSvc.data;
                    doValidation();
                    // Storage event triggered by setting local storage after a successful post/put, so isSaving can now be set to false
                    model.saving.isSaving = false;
                });
            }
        }

        model.errors = [];
        model.getSubmissionStatus = statementStorageSvc.getSubmissionStatus;
        model.reviewTeamId = parseInt($stateParams.reviewTeamId);
        model.reviewId = null;
        model.isDataReady = false;
        model.noChangeOption = false;
        model.teamMemberTypeNames = teamMemberTypeNames;
        model.formatDate = helperSvc.formatDate;
        model.isAdjunct = oauth.isAdjunct();
        model.programReviewTypeIds = programReviewTypeIds;
        model.hasValidationRan = statementValidationSvc.hasBeenInvoked;
        model.isIE = helperSvc.isIE11();
        model.reviewType = userReviewSvc.data.currentUserReviewTeam.reviewTypeCode;
        model.programReviewActionCodes = programReviewActionCodes;
        model.programReviewActionNames = programReviewActionNames;
        model.statementTypeLabel = angular.copy(statementTypeLabel);
        model.statementTypeIds = statementTypeIds;
        model.creationOfStatementInProcess = false;
        model.submitButtonLabel = "Submit To ";
        model.returnButtonLabel = "Return To ";

        model.STATEMENTEDITORACCESS = {
            USERCURRENTSTATEMENT: 1,
            USERNOSTATEMENT: 2,
            USERSUBMITTEDSTATEMENT: 3,
            ADMIN: 4
        }
        model.getStatementAvailabilitySetting = function () {
            if (oauth.isAdmin() && !model.isAdjunct)//need to check this first so that the else isnt hit ever
            {
                if (!model.data.statement.submittedTimestamp && model.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN)
                    return model.STATEMENTEDITORACCESS.USERCURRENTSTATEMENT
                else
                    return model.STATEMENTEDITORACCESS.ADMIN;
            }
            //the current statement being edited matches the current user
            if (model.data.statement.teamMemberTypeId === model.data.currentReviewTeam.teamMemberTypeId) {
                return model.STATEMENTEDITORACCESS.USERCURRENTSTATEMENT;
            }
            else {
                //user has a submitted statement
                if (statementStorageSvc.getLatestStatementByCurrentTeamMemberType() !== null) {
                    return model.STATEMENTEDITORACCESS.USERSUBMITTEDSTATEMENT;
                } else {//user has not submitted a statement and is not the current editor 
                    return model.STATEMENTEDITORACCESS.USERNOSTATEMENT;
                }
            }
        }

        model.editStatement = function () {
            var userStatementAvailability = model.getStatementAvailabilitySetting();
            var statementId;
            if (userStatementAvailability === model.STATEMENTEDITORACCESS.USERNOSTATEMENT || userStatementAvailability === model.STATEMENTEDITORACCESS.USERSUBMITTEDSTATEMENT) {
                //get latest submitted version
                var usersStatement = statementStorageSvc.getLatestStatementByIsSubmitted();
                if (usersStatement === null) {
                    return alertSvc.openModalAlert("The first draft of the statement has not yet been submitted. After the first submission, all available versions of the statement will be available to view.", "No statement available");
                } else {
                    statementId = usersStatement.statementId;
                }
            } else if (userStatementAvailability === model.STATEMENTEDITORACCESS.USERCURRENTSTATEMENT || userStatementAvailability === model.STATEMENTEDITORACCESS.ADMIN) {
                statementId = model.data.statement.statementId;
            } else {
                return alertSvc.openModalAlert("Something went wrong. Please contact Abet.");
            }
            $state.go('statementTool', { statementId: statementId });
        };

        model.createTemplate = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/createTemplate.html',
                size: 'lg',
                controller: 'createTemplateCtrl',
                backdrop: 'static',
                resolve: {
                    programs: function () { return model.programs; }
                }
            });
        };

        model.openInstructions = function () {
            alertSvc.openPDFModal(
                '/Content/files/Draft-Final-Statement-Instructions.pdf',
                'Statement Editor Instructions'
            );
        };

        model.selectProgram = function (program) {
            selectedId = program.programId;
        };

        model.isSelected = function (program) {
            return program.programId === selectedId;
        };

        model.createFinalStatement = function () {
            model.creationOfStatementInProcess = true;
            alertSvc.confirm("You are about to create the final. Do you want to proceed?", doCreate, null, null, cancelFunc);

            function doCreate() {
                statementStorageSvc.createFinalStatement(model.data.statement).then(function () {
                    alertSvc.addModalAlertSuccess("Statement successfully created.");
                    model.statementExists = true;
                    model.creationOfStatementInProcess = false;
                });
            }
            function cancelFunc() {
                model.creationOfStatementInProcess = false;
            }
        }

        model.createPost30DayStatement = function () {
            model.creationOfStatementInProcess = true;
            alertSvc.confirm("You are about to create the Post-30-Day statement. Do you want to proceed?", doCreate, null, null, cancelFunc);

            function doCreate() {
                statementStorageSvc.createPost30DayStatement(model.data.statement).then(function () {
                    alertSvc.addModalAlertSuccess("Statement successfully created.");
                    model.statementExists = true;
                    model.creationOfStatementInProcess = false;
                });
            }

            function cancelFunc() {
                model.creationOfStatementInProcess = false;
            }
        }

        //CREATES BOTH THE STATEMENT AND ALL STATEMENTDETAILS and adds intro for each statementdetail
        model.createStatement = function () {
            let programAudit, programAuditAccess, previousStatement;

            $q.all([
                programAuditSvc.getProgramAuditByReviewTeamId(model.reviewTeamId).then(data => data ? data.find(programAudit => (programAudit.isCurrent && programAudit.isLocked) || model.reviewType === 'IR' ) : null),
                programAuditAccessSvc.getProgramAuditAccessByReviewTeamId(model.reviewTeamId),
                statementStorageSvc.getInterimReviewPreviousStatement(model.reviewTeamId)
            ]).then(data => {
                [programAudit, programAuditAccess, previousStatement] = data;
                const hasProgramAudits = !!programAudit?.programAuditDetailDtos.length;
                const confirmationMsg =
                    `${previousStatement ? "Previous statement for interim program review(s) found. " : ""}` +
                    `${hasProgramAudits ? "Finalized program audit found. ": ""}` +
                    `${previousStatement || hasProgramAudits ? "Data will be transferred to the statement tool. " : ""}` +
                    `${!previousStatement && !hasProgramAudits ? "You are about to create a new statement. " : "" }` +
                    `Do you want to proceed?`;

                alertSvc.confirm(confirmationMsg, doCreate);
            }).catch(() => {
                alertSvc.addPersistentAlertDanger("Data could not be retrieved for this statement.  Please contact tech support.");
            });

            function doCreate() {
                var reviewTeamId = model.reviewTeamId;
                var commissionId = statementStorageSvc.data.currentReviewTeam.commissionId;
                var reviewTypeCode = statementStorageSvc.data.currentReviewTeam.reviewTypeCode;
                var evaluatorReportData = evaluatorReportSvc.data.evaluatorReport;
                var programs = model.programs;
                var reviewTeamMembers = model.reviewTeamMembers;
                statementStorageSvc.createInitialStatement(reviewTeamId, commissionId, reviewTypeCode, evaluatorReportData, programs, reviewTeamMembers, programAudit, programAuditAccess, previousStatement).then(function () {
                    // Statement generation and save to server complete, redirect to statement tool
                    model.statementExists = true;
                    if (programAudit || previousStatement)
                        $state.go('statementTool', { statementId: statementStorageSvc.data.statement.statementId });
                    else {
                        alertSvc.addModalAlertSuccess("Statement successfully created.");
                        model.createTemplate();
                    }
                }).catch(function (error) {
                    console.log(error);
                    model.statementExists = false;
                    alertSvc.addAlertWarning('An error occured while attempting to create this statement.');
                });
            }

            function handleError(error) {

            }
        };

        model.submit = function () {
            alertSvc.confirm("You are about to submit the statement to the next person in the editing chain. This process is irreversible. Do you want to proceed?",
                submitFunc, null, null, null, "Submit Statement");

            function submitFunc() {
                model.data.statement.note = angular.copy(model.statementNote);
                model.saving.isSaving = true;

                // Clear flag indicating RA can be edited by adjunct
                model.data.statement.statementDetailDtos.forEach(section => {
                    section.statementJson.forEach(findingType => { delete findingType.isRecommendedActionEditableByAdjunct });
                });

                if (model.data.statement.statementTypeId === statementTypeIds.FINAL && dueResponseSvc.data.thirtyDayPostResponse && model.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.ADJUNCT) {
                    statementStorageSvc.submitFinalForPost30Day().then(function () {
                        model.saving.isSaving = false;
                        alertSvc.addAlertSuccess("Statement successfully submitted.");
                        userReviewSvc.getCurrentCycleReviews();
                    });
                }
                else {

                    var willBeAdjunctStatement = model.data.statement.statementStatusId === statementStatuses.WAITINGFORED2SUBMIT;
                    var previousTeamMemberId = getCurrentTeamMemberTypeId();
                    statementStorageSvc.submit(null).then(function () {
                        model.saving.isSaving = false;
                        if (!willBeAdjunctStatement && model.data.statement.statementTypeId !== statementTypeIds.COMMISSIONEDITING)//else this is handled through a script server side
                            sendStatementSubmissionEmail(false, previousTeamMemberId);

                        alertSvc.addAlertSuccess("Statement successfully submitted.");
                        userReviewSvc.getCurrentCycleReviews();
                    }).catch(function (error) {
                        console.log(error);
                        model.saving.isSaving = false;
                        alertSvc.addAlertWarning('An error occured while attempting to submit this statement.');
                    });
                }
            }
        };

        model.isAdminReceivedStatus = function (statement) {
            if (statement.teamMemberTypeId == teamMemberTypeNames.ADMIN) {
                return !!statement.submittedTimestamp && ((statement.statementTypeId == statementTypeIds.FINAL) || (statement.statementTypeId == statementTypeIds.POSTTHIRTYDAY));
            }
            return false;
        };

        model.userReturnDisabled = function () {
            if (model.saving.isSaving) {
                return true;
            }
            if (model.getStatementAvailabilitySetting() !== model.STATEMENTEDITORACCESS.USERCURRENTSTATEMENT) {
                return true;
            }
            else {
                return model.errors.some(function (errorSection) {
                    return errorSection.errors.some(function (error) {
                        return error.message.toLowerCase().indexOf('recommended action') === -1;
                    });
                });
            }
            return false;
        };

        model.userSubmissionDisabled = function () {
            if (model.saving.isSaving) {
                return true;
            }
            if (model.getStatementAvailabilitySetting() !== model.STATEMENTEDITORACCESS.USERCURRENTSTATEMENT) {
                return true;
            }
            else {
                if (model.errors.length > 0 || !model.hasValidationRan())
                    return true;
            }
            return false;
        };

        model.isCurrentUserStatement = function () {
            return model.getStatementAvailabilitySetting() === model.STATEMENTEDITORACCESS.USERCURRENTSTATEMENT;
        };

        model.isRecommendedActionEditableByAdjunct = function (statement) {
            return statement && statement.statementDetailDtos && statement.statementDetailDtos.some(section =>
                section.statementJson && section.statementJson.some(findingType => findingType.isRecommendedActionEditableByAdjunct)
            );
        }

        model.return = function () {
            alertSvc.confirm("You are about to return the statement to the previous person in the editing chain. This process is irreversible. Do you want to proceed?",
                returnFunc, null, null, null, "Return Statement");
            var isReturn = true;
            var previousTeamMemberId = getCurrentTeamMemberTypeId();
            function returnFunc() {
                model.data.statement.note = angular.copy(model.statementNote);
                statementStorageSvc.submit(isReturn).then(function () {
                    if (model.data.statement.statementTypeId !== statementTypeIds.COMMISSIONEDITING)
                        sendStatementSubmissionEmail(isReturn, previousTeamMemberId);
                    alertSvc.addAlertSuccess("Statement successfully returned.");
                }).catch(function (error) {
                    console.log(error);
                    model.saving.isSaving = false;
                    alertSvc.addAlertWarning('An error occured while attempting to return this statement.');
                });
            }
        };

        model.viewRA = function (statement) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/viewRecommendedAction.html',
                size: 'lg',
                controller: 'viewRecommendedActionCtrl',
                resolve: {
                    statement: function () { return statement; }
                }
            });
        };

        model.getUsersRecommendedAction = function (programId) {//should never get to a point where this is called and theres no user statement because ng-if on if theres a users statment
            var usersStatement = statementStorageSvc.getLatestStatementByCurrentTeamMemberType();
            if (usersStatement && usersStatement.statementDetailDtos) {
                var statementDetail = helperSvc.getFirstMatch(usersStatement.statementDetailDtos, 'programId', programId);
                return statementDetail.recommendedAction;
            }
            return null
        }

        model.userHasStatement = function () {
            return statementStorageSvc.getLatestStatementByCurrentTeamMemberType() ? true : false;
        }

        model.viewProgramRecommendedActions = function (programId) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/programRecommendedActions.html',
                size: 'lg',
                controller: 'programRecommendedActionsCtrl',
                resolve: {
                    programId: function () { return programId; }
                }
            });
        }

        model.getLatestProgramRecommendedActions = function (programId) {
            var recommendedActionsArray = [];
            var statementArray = [];
            var teamMemberTypeArray = [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.REPORTTEAMCHAIR, teamMemberTypeNames.EDITOR1, teamMemberTypeNames.EDITOR2]

            recommendedActionsArray.push({ role: "PEV", recommendedAction: statementStorageSvc.getPEVRA(programId) });
            if (model.data.statementHistory) {
                var statementsByType = helperSvc.getFilteredArray(model.data.statementHistory, 'statementTypeId', [model.data.statement.statementTypeId], true);

                if (statementsByType && statementsByType.length > 0) {
                    angular.forEach(teamMemberTypeArray, function (type) {
                        var statementReturned = helperSvc.getFirstMatch(statementsByType, 'teamMemberTypeId', type);
                        if (statementReturned)
                            statementArray.push(statementReturned);
                    });
                }

                if (statementArray && statementArray.length > 0) {
                    angular.forEach(statementArray, function (statement) {
                        var program = helperSvc.getFirstMatch(statement.statementDetailDtos, 'programId', programId)

                        if (program) {
                            recommendedActionsArray.push({ role: statement.teamMemberTypeName, recommendedAction: program.recommendedAction })
                        }
                    })
                }
            }
            return recommendedActionsArray;
        }

        model.openStatementPreview = function (statement) {
            if (model.data.currentReviewTeam.commissionId === commissionIds.CAC) {
                reviewTeamMemberSvc.getReviewTeamMembers(model.data.reviewTeamId).then(function (data) {

                    statementStorageSvc.data.reviewTeamMembers = data.sort(function (a, b) {
                        return a.teamMemberTypeId - b.teamMemberTypeId;
                    });

                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/statement/templates/modals/viewStatementPreview.html',
                        size: 'xl',
                        controller: 'viewStatementPreviewCtrl',
                        resolve: {
                            currentStatement: function () { return statement; }
                        }
                    });
                });
            } else {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/statement/templates/modals/viewStatementPreview.html',
                    size: 'xl',
                    controller: 'viewStatementPreviewCtrl',
                    resolve: {
                        currentStatement: function () { return statement; }
                    }
                });
            }
        };

        model.openNoteModal = function (note, teamMemberTypeName) {
            alertSvc.openModalAlert(note, "Submission Note (" + teamMemberTypeName + ")");
        }

        model.isCurrentUserFinalCreator = function () {
            return model.data.statement.submittedToTeamMemberTypeId === model.data.currentReviewTeam.teamMemberTypeId && (model.data.statement.statementTypeId === statementTypeIds.DRAFT && (model.data.statement.statementStatusId === statementStatuses.SENTTOINSTITUTION || model.data.statement.statementStatusId === statementStatuses.RESENTTOINSTITUTION))
        }

        model.canStartFinalStatement = function () {
            var firstSubmittedStatement = helperSvc.getFirstMatch(model.data.statementHistory, 'statementStatusId', statementStatuses.SENTTOINSTITUTION);

            if (dueResponseSvc.data.thirtyDayResponse && ((dueResponseSvc.data.thirtyDayResponse.stream_id) || (dueResponseSvc.data.thirtyDayResponse.responseNeeded === false))) {
                return true;
            }
            if (helperSvc.getDateDiff(firstSubmittedStatement.submittedTimestamp, new Date(), "days") >= 30) {
                return true;
            }
            return false;
        }

        model.thirtyDayPostStatementCreationAvailable = function () {
            return ((model.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || model.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR) && model.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN && model.data.statement.statementTypeId === statementTypeIds.FINAL && dueResponseSvc.data.thirtyDayPostResponse && dueResponseSvc.data.thirtyDayPostResponse.stream_id && model.data.currentReviewTeam) && model.data.statement.statementTypeId !== statementTypeIds.COMMISSIONEDITING;
        }

        //model.commissionEditingStatementAvailable = function () {//TODO : need parameters for making this available... like date maybe?
        //    return (model.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || model.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR) && model.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN && (model.data.statement.statementTypeId === statementTypeIds.FINAL || model.data.statement.statementTypeId === statementTypeIds.FINAL);

        //    //if post 30, wait till thats done? else if final wait till thats done?
        //}

        model.createCommissionEditingStatement = function () {
            alertSvc.confirm("You are about to create the Commission Editing Statement. Do you want to proceed?", doCreate);

            function doCreate() {
                statementStorageSvc.createCommissionEditingStatement(model.data.statement).then(function () {
                    alertSvc.addModalAlertSuccess("Statement successfully created.");
                    model.statementExists = true;
                });
            }
        }

        function getCurrentTeamMemberTypeId(statement) {
            switch (model.data.statement.statementStatusId) {
                case statementStatuses.WAITINGFORTCSUBMIT:
                    return (model.reviewType === 'IR') ? teamMemberTypeNames.REPORTTEAMCHAIR : teamMemberTypeNames.TEAMCHAIR;
                case statementStatuses.WAITINGFORED1SUBMIT:
                    //ED1
                    return teamMemberTypeNames.EDITOR1;
                case statementStatuses.WAITINGFORED2SUBMIT:
                    //ED2
                    return teamMemberTypeNames.EDITOR2;
                case statementStatuses.WAITINGFORADJSUBMIT:
                    //ADJ
                    return teamMemberTypeNames.ADJUNCT;
                case statementStatuses.ADMINPREPFORSEND:
                case statementStatuses.SENTTOINSTITUTION:
                default:
                    //ADMIN
                    //Insitution
                    //not sure who to sent to
                    return 0;
            }
        }

        function getAccreditationCycle(year) {
            var start = parseInt(year) - 1;
            return start + '-' + (year % 100);
        }

        function sendStatementSubmissionEmail(isReturn, previousTeamMemberId) {
            var teamMemberTypeId = getCurrentTeamMemberTypeId();

            var nextTeamMember = getNextTeamMember(teamMemberTypeId);

            var teamChair = { personId: null };
            if (previousTeamMemberId !== teamMemberTypeNames.TEAMCHAIR && teamMemberTypeId !== teamMemberTypeNames.TEAMCHAIR && teamMemberTypeId !== teamMemberTypeNames.REPORTTEAMCHAIR) {
                teamChair = getCurrentTeamChair();
            }

            if (nextTeamMember && nextTeamMember.personId) {
                var messageParams = [
                    {
                        messageParameterName: 'ReviewYearSpan', messageParameterValue: getAccreditationCycle(model.data.currentReviewTeam.reviewYear)
                    },
                    {
                        messageParameterName: 'SubmittedByTypeName', messageParameterValue: teamMemberTypeIds[previousTeamMemberId]
                    },
                    {
                        messageParameterName: 'SubmittedToTypeName', messageParameterValue: teamMemberTypeIds[teamMemberTypeId]
                    },
                    {
                        messageParameterName: 'StatementTypeName', messageParameterValue: statementTypeNamesById[model.data.statement.statementTypeId]
                    },
                    {
                        messageParameterName: 'PersonName', messageParameterValue: currentUser.profile.firstName + ' ' + currentUser.profile.lastName
                    },
                    {
                        messageParameterName: 'PersonEmail', messageParameterValue: model.personEmail.emailAddress
                    },
                    {
                        messageParameterName: 'InstitutionName', messageParameterValue: model.data.currentReviewTeam.organizationName
                    },
                    {
                        messageParameterName: 'InstitutionSortName', messageParameterValue: model.data.currentReviewTeam.organizationSortName
                    },
                    {
                        messageParameterName: 'CommissionName', messageParameterValue: model.data.currentReviewTeam.commissionName
                    },
                    {
                        messageParameterName: 'ReviewType', messageParameterValue: model.data.currentReviewTeam.reviewTypeName
                    },
                    {
                        messageParameterName: 'Note', messageParameterValue: model.statementNote
                    }

                ];

                if (isReturn) {
                    messageParams.push({
                        messageParameterName: 'SubmittedToTypeName', messageParameterValue: teamMemberTypeIds[teamMemberTypeId]
                    });
                }

                var messageTemplateId = isReturn ? messageTemplateTypes.STATEMENTRETURNED : messageTemplateTypes.STATEMENTSUBMITTED;

                messageSvc.buildAndSendMessage([nextTeamMember.personId], [teamChair.personId], messageTemplateId, messageParams);
            }
        }

        function getNextTeamMember(teamMemberTypeId) {
            var nextTeamMember = model.myReviewTeamMembers.reduce(function (nextTeamMember, member) {
                if (member.teamMemberTypeId === teamMemberTypeId &&
                    (member.endDate === null || new Date(member.endDate) >= new Date()) &&
                    (nextTeamMember === null || new Date(member.startDate) >= new Date(nextTeamMember.startDate))
                ) {
                    return member;
                }
                return nextTeamMember;
            }, null);

            return nextTeamMember;
        }

        function getCurrentTeamChair() {
            var teamChair = model.myReviewTeamMembers.reduce(function (currentTeamChair, member) {
                if ((member.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || member.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR) &&
                    (member.endDate === null || new Date(member.endDate) >= new Date()) &&
                    (currentTeamChair === null || new Date(member.startDate) >= new Date(currentTeamChair.startDate))
                ) {
                    return member;
                }
                return currentTeamChair;
            }, null);

            return teamChair;
        }

        model.runValidation = function () {
            doValidation();
        }

        function doValidation() {
            model.errors = statementValidationSvc.validateStatementBySection(model.data.statement);            //model.errors = statementValidationSvc.validateStatementBySection(model.data.statement);
            statementValidationSvc.invokeValidation();
        }

        model.isParent = function (statement) {
            var statements = model.data.statementHistory.filter(function (s) {
                return statement.statementTypeName == s.statementTypeName;
            });

            return statement.insertedTimestamp == statements[0].insertedTimestamp;
        };

        model.previousStatementWasReturned = function () {
            var previousStatement = statementStorageSvc.data.statementHistory[1];

            if (previousStatement.teamMemberTypeId > model.data.statement.teamMemberTypeId && ((model.data.statement.teamMemberTypeId !== teamMemberTypeNames.TEAMCHAIR || model.data.statement.teamMemberTypeId !== teamMemberTypeNames.REPORTTEAMCHAIR) && previousStatement.teamMemberTypeId !== teamMemberTypeNames.ADMIN))
                return true;

        }

        var activate = function () {
            var dataArray = [];
            model.statementNote = null;//needs its own variable because of concurrency issues with the statement saving

            var getStatementData = { //this might not be nec any more either
                dataHolder: model,
                dataLocationName: 'statement',
                svcCallback: statementStorageSvc.getByReviewTeamId,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true,
            };
            dataArray.push(getStatementData);


            var userReview;//as is this
            if (oauth.isAdmin()) {
                userReview = {
                    dataHolder: statementStorageSvc,
                    dataLocationName: 'data.currentReviewTeam',
                    svcCallback: userReviewSvc.getCurrentReviewTeamAdmin,
                    svcCallbackArguments: [model.reviewTeamId],
                    odataResource: true,
                    optionalCallback: function () {
                        if (model.isAdjunct) {
                            statementStorageSvc.data.currentReviewTeam.teamMemberTypeId = teamMemberTypeNames.ADJUNCT;
                        }
                    }
                }
                dataArray.push(userReview);

            } else {
                userReview = {
                    dataHolder: statementStorageSvc,
                    dataLocationName: 'data.currentReviewTeam',
                    svcCallback: userReviewSvc.getCurrentReviewTeam,
                    svcCallbackArguments: [model.reviewTeamId],
                    odataResource: true
                };
                dataArray.push(userReview);
            }

            var getReviewTeamMemberData = {
                dataHolder: model,
                dataLocationName: 'reviewTeamMembers',
                svcCallback: reviewTeamMemberSvc.getReviewTeamMembers,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true,
            }
            dataArray.push(getReviewTeamMemberData);

            var getReviewTeamMemberData = {
                dataHolder: model,
                dataLocationName: 'myReviewTeamMembers',
                svcCallback: reviewTeamMemberSvc.getMyReviewTeamMembers,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true,
            }
            dataArray.push(getReviewTeamMemberData);

            model.personId = parseInt(currentUser.profile.personId);

            var getPersonEmailData = {
                dataHolder: model,
                dataLocationName: 'personEmailDtos',
                svcCallback: personEmailSvc.getByPersonId,
                svcCallbackArguments: [model.personId],
                odataResource: true,
            }
            dataArray.push(getPersonEmailData);

            var getStatementFindingTypes = {
                dataHolder: statementStorageSvc,
                dataLocationName: 'codes.statementFindingTypes',
                svcCallback: statementSvc.getStatementFindingTypes,
                odataResource: true,
            }
            dataArray.push(getStatementFindingTypes);

            var programReviewsDataSource = {
                dataHolder: statementStorageSvc,
                dataLocationName: 'data.statementProgramReviews',
                svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                svcCallbackArguments: [model.reviewTeamId],
                orderByProperty: 'programReviewId',
                odataResource: true
            }
            dataArray.push(programReviewsDataSource);

            var programReviewsFinalActionDataSource = {
                dataHolder: model,
                dataLocationName: 'programReviewsWithFinalAction',
                svcCallback: programReviewFinalActionSvc.getReviewsByReviewTeamId,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true
            }
            dataArray.push(programReviewsFinalActionDataSource);

            const dueResponseDataSource = {
                dataHolder: model,
                dataLocationName: 'dueResponseData',
                svcCallback: dueResponseSvc.getDueResponsesByReviewTeamId,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true
            }
            dataArray.push(dueResponseDataSource);

            helperSvc.getData(dataArray).then(function () {
                // Filter out programs that are TR/TV without accreditation end dates
                // 11/14/2019 AMS team decided to include phasing-out/phased-out programs in the statement.
                // 3/16/2020 Accd Dept requested to exclude phaining-out/phased-out programs in the statement.
                // 10/292021 Because NPC is being implemented, terminating programs are excluded from the statement process
                var filteredPrograms = statementStorageSvc.data.statementProgramReviews.filter(function (programReview) {
                    if ((programReview.programReviewTypeCode.toLowerCase() === 'tr' || programReview.programReviewTypeCode.toLowerCase() === 'tv')
                        && (statementStorageSvc.data.currentReviewTeam.reviewYear > 2022)) {
                        //if (programReview.accreditationEndDate === null ||
                        //    programReview.accreditationEndDate < statementStorageSvc.data.currentReviewTeam.reviewYear + '-10-01T00:00:00Z')
                        return false;
                    }
                    return true;
                });

                // Redirect if there are no eligible programs to make a statement with
                if (!filteredPrograms.length)
                    $state.go($state.current.name, { view: userReviewSvc.slugs.TEAM });
                // Avoid resetting array if we don't have to so we don't need to test all possible side effects.
                if (filteredPrograms.length != statementStorageSvc.data.statementProgramReviews.length) statementStorageSvc.data.statementProgramReviews = filteredPrograms;

                model.data = statementStorageSvc.data;
                model.isReport = model.data.currentReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT;
                if (model.data.statement && Object.keys(model.data.statement).length > 0) {
                    model.statementExists = true;
                } else {
                    model.statementExists = false;
                }

                if (model.personEmailDtos.length > 1) {
                    var allAbetEmail = model.personEmailDtos.filter(function (email) {
                        return email.contactTypeId == 1 //All ABET Activities
                    });
                    model.personEmail = allAbetEmail[0];
                } else {
                    model.personEmail = model.personEmailDtos[0];
                }

                eventSvc.listen(doValidation, 'pafUploaded', $scope);

                var programs = model.data.statementProgramReviews.map(function (program) {
                    var programFinalReview = helperSvc.getFirstMatch(model.programReviewsWithFinalAction, 'programId', program.programId);
                    if (programFinalReview) {
                        program.lastAction = programFinalReview.lastAction;
                        program.lastActionReviewCycle = programFinalReview.lastActionReviewCycle;
                    }
                    return program;
                })
                model.programs = programs;
                //for creating final draft

                doValidation();
                const submitTo = statementStorageSvc.getSubmittedToTeamMemberTypeAndStatus(model.data.statement, false);
                model.submitButtonLabel += submitTo.submittedToTeamMemberTypeName;

                const returnTo = statementStorageSvc.getSubmittedToTeamMemberTypeAndStatus(model.data.statement, true);
                model.returnButtonLabel += returnTo.submittedToTeamMemberTypeName;

                model.isDataReady = true;
            });
        }();
    };
    module.controller('statementVolunteerCtrl', statementVolunteerCtrl);

}(angular.module('statement')));