(function (module) {

    var conductEditCtrl = function (alert, $scope, $stateParams, $uibModalInstance, volunteerTermsSvc, alertSvc, $filter, helperSvc) {
        var model = $scope;
        var term = volunteerTermsSvc.getAgreedTerm();

        model.volunteerId = $stateParams.volunteerId;
        model.title = 'ABET Standards of Conduct';
        model.hasComplied = (term) ? true : false;
        model.term = term || {};

        model.save = function () {
            model.term.volunteerId = model.volunteerId;

            volunteerTermsSvc.create(model.term).then(function () {
                alertSvc.removePersistentAlert(alert);
                alertSvc.addAlertSuccess("Agreement successfully created.");
                model.close();
            });
        };

        model.close = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('conductEditCtrl', conductEditCtrl);

}(angular.module('volunteer')));