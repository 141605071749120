(function (module) {

    var reviewValidationSvc = function (eventSvc, userReviewSvc, programReviewSvc, $q, statementStorageSvc, statementValidationSvc,
        programAuditSvc, programAuditValidationSvc, programAuditAccessSvc, teamMemberTypeNames) {

        var factory = {};

        factory.invokeValidation = function () {
            eventSvc.broadcast('ValidateReview');
        };

        factory.listenToValidate = function (callback, scope) {
            eventSvc.listen(callback, 'ValidateReview', scope);
            eventSvc.listen(callback, 'ValidateReadiness', scope);
            eventSvc.listen(callback, 'ValidateStatement', scope);
            eventSvc.listen(callback, 'ValidateProgramAudit', scope);
        };

        factory.validate = function (review, programs, statement, programAudit, programAuditAccess) {
            return getProgramAuditData(programAudit, programAuditAccess, review.reviewTeamId).then(([programAudit, programAuditAccess])=> {
                // Main validation for all aspects of a review.
                var selfStudyResults = validateSelfStudy(review, programs);
                var programAuditResults = validateProgramAudit(programAudit, programAuditAccess, programs);
                var statementResults = validateStatement(statement);
                return [...selfStudyResults, ...programAuditResults, ...statementResults];
            });
        };

        function getProgramAuditData(programAudits, programAuditAccess, reviewTeamId) {
            // Prevent side-effects from modifying/reformatting the data during validation
            programAudits = angular.copy(programAudits);
            programAuditAccess = angular.copy(programAuditAccess);
            // Use passed in data or load from WebAPI as needed
            return $q.all([
                programAudits && $q.when(programAudits) || programAuditSvc.getProgramAuditByReviewTeamId(reviewTeamId),
                programAuditAccess && $q.when(programAuditAccess) || programAuditAccessSvc.getProgramAuditAccessByReviewTeamId(reviewTeamId)
            ]).then(([programAudits, programAuditAccess]) => {
                // Find, deserialize current program audit data
                const programAudit = Array.isArray(programAudits) ?
                    programAudits.find(programAudit =>
                        programAudit.isCurrent
                    ) :
                    programAudits;
                if (programAudit)
                    programAudit.programAuditDetailDtos = programAudit.programAuditDetailDtos.filter(programAuditDetail =>
                        programAuditDetail.isCurrent
                    ).map(programAuditDetail => {
                        if (typeof programAuditDetail.programAuditJson  === 'string')
                            programAuditDetail.programAuditJson = angular.fromJson(programAuditDetail.programAuditJson);
                        return programAuditDetail;
                    });

                return [programAudit, programAuditAccess];
            });
        }

        function validateStatement(statement, evaluatorReport) {
            var statementResults = [];               
            var results = [];
            var errorSections = statementValidationSvc.validateStatementBySection(statement);

            errorSections.forEach(function (errorSection) {
                results.push.apply(results, errorSection.errors);
            });

            var raIncomplete = results.some(function (error) {
                return error.type === "RA";
            });

            var pevRaIncomplete = results.some(function (error) {
                return error.type === "PEVRA";
            });

            var textMissingForFinding = results.some(function (error) {
                return error.type === "FT";
            });

            var pafMissing = results.some(function (error) {
                return error.type === "PAF";
            });

            var startDateMissing = results.some(function (error) {
                return error.type === "START";
            });

            var termDateMissing = results.some(function (error) {
                return error.type === "TERM";
            });

            var irMissing = results.some(function (error) {
                return error.type === "IR";
            });

            var sevenDayMissing = results.some(function (error) {
                return error.type === "7DAY";
            });

            var thirtyDayMissing = results.some(function (error) {
                return error.type === "30DAY";
            });

            var postThirtyDayMissing = results.some(function (error) {
                return error.type === "POST30DAY";
            });

            if (pafMissing) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "PAF document upload is required." });
            }
            if (sevenDayMissing) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Seven-day response(s) missing." });
            }
            if (thirtyDayMissing) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "30-day response(s) missing." });
            }
            if (postThirtyDayMissing) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Post-30-day response(s) missing." });
            }
            if (raIncomplete) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Each program in the statement must have a recommended action." });
            }
            if (textMissingForFinding) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Statement has incomplete findings." });
            }
            if (irMissing) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Interim program reviews must have last visit findings, progress since last review, and interim response status." });
            }
            if (pevRaIncomplete) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Each program in the statement must have a pev recommended action." });
            }
            if (startDateMissing) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Each newly accredited program must have a recommended accreditation start date." });
            }
            if (termDateMissing) {
                statementResults.push({ slug: userReviewSvc.slugs.STATEMENT, message: "Each terminated program in the statement must have a recommended accreditation end date." });
            }
            return statementResults;
        }
        
        function validateSelfStudy(review, programs) {
            var selfStudyResults = [];

            // All criteria are required on self-study
            var selfStudiesIncomplete = review.selfStudySubmittedTimestamp == null;

            if (selfStudiesIncomplete) {
                selfStudyResults.push({ slug: userReviewSvc.slugs.SELFSTUDY, message: "Self studies must be completed and submitted for all programs." });
            }

            //if the current person is the TC
            if (userReviewSvc.data.currentUserReviewTeam.teamMemberTypeId == teamMemberTypeNames.TEAMCHAIR && programs != null) {
                angular.forEach(programs, function (pr) {
                    if (!pr.selfStudyConfirmedByTC) {
                        var messageText = "The TC has not allowed PEV Access for : " + pr.programDto.programDetailDto.programName + ' (' + pr.programDto.programDetailDto.degreeCode + ') ';
                        selfStudyResults.push({ slug: userReviewSvc.slugs.SELFSTUDY, message: messageText });
                    }

                });
            }
               


            return selfStudyResults;
        }         

        function validateProgramAudit(programAudit, programAuditAccess, programs) {
            const programAuditResults = [];

            const currentReviewTeamMemberTypeId = userReviewSvc.data.currentUserReviewTeam.teamMemberTypeId;
            const currentReviewTeamMemberId = userReviewSvc.data.currentUserReviewTeam.reviewTeamMemberId;

            const errorSummary = programAuditValidationSvc.validateProgramAuditBySection(programAudit, programAuditAccess, programs, currentReviewTeamMemberTypeId, currentReviewTeamMemberId).flatMap(errorSection =>
                errorSection.errors
            ).reduce((errorSummary, error) => {
                errorSummary[error.type] = true;
                return errorSummary;
            },
                {}
            );

            if (errorSummary[programAuditValidationSvc.errorTypes.NOTCREATED])
                programAuditResults.push({ slug: userReviewSvc.slugs.PROGRAMAUDIT, message: "One or more program audits not started." });

            if (errorSummary[programAuditValidationSvc.errorTypes.SUMMARY])
                programAuditResults.push({ slug: userReviewSvc.slugs.PROGRAMAUDIT, message: "One or more program audit summaries are incomplete." });

            if (errorSummary[programAuditValidationSvc.errorTypes.FT])
                programAuditResults.push({ slug: userReviewSvc.slugs.PROGRAMAUDIT, message: "One or more program audits have incomplete findings." });

            if (errorSummary[programAuditValidationSvc.errorTypes.RA])
                programAuditResults.push({ slug: userReviewSvc.slugs.PROGRAMAUDIT, message: "One or more program audits are missing recommended action(s)." });

            if (errorSummary[programAuditValidationSvc.errorTypes.NOTSUBMITTED])
                programAuditResults.push({ slug: userReviewSvc.slugs.PROGRAMAUDIT, message: "One or more program audits are ready to be submitted." });

            if (errorSummary[programAuditValidationSvc.errorTypes.NOTREVIEWED])
                programAuditResults.push({ slug: userReviewSvc.slugs.PROGRAMAUDIT, message: "One or more program audits are ready to be reviewed." });

            if (errorSummary[programAuditValidationSvc.errorTypes.NOTFINALIZED])
                programAuditResults.push({ slug: userReviewSvc.slugs.PROGRAMAUDIT, message: "Audit is ready to be finalized." });

            return programAuditResults;
        }


        factory.populateWarnings = function (navigation, programAudit, programAuditAccess) {
            factory.validate(userReviewSvc.data.currentReviewTeam, programReviewSvc.data.programs, statementStorageSvc.data.statement, programAudit, programAuditAccess).then(function (results) {
                for (var i = 0; navigation && i < navigation.length; i++) {
                    navigation[i].warningMessages = [];
                }

                for (var i = 0; i < results.length; i++) {
                    var slug = results[i].slug;

                    for (var j = 0; navigation && j < navigation.length; j++) {
                        var tab = navigation[j];

                        if (tab.slug === slug) {
                            tab.warningMessages.push(results[i].message);
                        }
                    }
                }
            });
        };

        return {
            invokeValidation: factory.invokeValidation,
            listenToValidate: factory.listenToValidate,
            validate: factory.validate,
            populateWarnings: factory.populateWarnings
        };
    };

    module.factory('reviewValidationSvc', reviewValidationSvc);

})(angular.module('userReview'));