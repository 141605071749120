/// <reference path="createtemplatectrl.js" />
(function (module) {

    var editRecommendedActionCtrl = function ($q, $scope, $uibModalInstance, alertSvc, currentSection, dueResponsePropNames, 
        statementTypeIds, statementStorageSvc, reviewTypeIds, findingStatusTypeIds, statementTemplateSvc, statementSvc,
        actionSvc, programReviewSvc, evaluatorReportSvc, teamMemberTypeNames, statementFindingTypes, programReviewTypeIds, actionCodes,
        dueResponsePropNamesByTypeId
    ) {

        var model = $scope;

        model.dataIsReady = false;
        model.teamMemberTypeNames = teamMemberTypeNames;
        model.title = 'Edit Recommended Action';
        model.isDataReady = false;

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.saveRecommendedActions = function (section) {
            if (model.isSubmitting) return;
            model.isSubmitting = true;

            var evaluatorReport = angular.copy(evaluatorReportSvc.data.evaluatorReport);
            var updatedDetail = evaluatorReport.evaluatorReportDetailDtos.find(function (detail) {
                return detail.programId === section.programId;
            });

            //must get matching evaluator detail and change those properties
            if(model.isDraftStatement){
                updatedDetail.recommendedAction = model.pevRecommendedAction;
                updatedDetail.statementJson = angular.toJson(section.statementJson);
            }

            
            var statement = angular.copy(model.data.statement);
            statement.statementDetailDtos = model.data.currentStatementDetailDtos;
            //get the detail index
            var statementDetailIndex = statement.statementDetailDtos.map(function(o){ return o.programId;}).indexOf(section.programId);
            statement.statementDetailDtos[statementDetailIndex] = section;

            // Clear start date of new programs if action is NA
            if (statementStorageSvc.isNewProgram(section) && section.recommendedAction === actionCodes.NA) {
                section.newProgramStartDate = null;
            }

            var evaluatorReportPromise = evaluatorReportSvc.update(evaluatorReport);
            var statementPromise = statementStorageSvc.save(statement);

            $q.all([evaluatorReportPromise, statementPromise]).then(
                function onSuccess() {
                    alertSvc.addModalAlertSuccess("Recommended action successfully saved.");
                    $uibModalInstance.close();
                },
                function onFailure(error) {
                    console.log('Error while saving recommended action:', error);
                    alertSvc.addAlertWarning('Recommended action could not be saved at this time.');
                    $uibModalInstance.close();
                }
            );
        }

        model.selectAction = function (section) {
            var statement = model.data.statement;            

            if (model.data.viewingAll) {
                model.data.currentStatementDetailDtos[model.sectionIndex] = section;
            } else {
                model.data.currentStatementDetailDtos[0].recommendedAction = section.recommendedAction;
            }

        }

        model.selectPevAction = function (action) {
            model.pevRecommendedAction = action;

        }

        function getPossibleActions(actionArray){
            var interimReviewTypes = [programReviewTypeIds.FOCUSEDREPORT, programReviewTypeIds.FOCUSEDVISIT, programReviewTypeIds.SHOWCAUSEREPORT, programReviewTypeIds.SHOWCAUSEVISIT];
            var possibleActions = [];
            var program = programReviewSvc.data.programs.find(function(program) {
                return program.programId === model.section.programId;
            });
            //need to check here if program has an updated response
            var programShortcomings;          
            programShortcomings = getLatestShortcomingsByResponseType(model.section.statementJson);        

            //ProgramReview Type IGR
            if(program.programReviewTypeCode === programReviewTypeIds.INITIALACCREDIATION){
                if(programShortcomings.none){            
                    possibleActions.push(actionArray.find(function(action){
                        return action.actionCode === actionCodes.NGR;
                    }));
                }   
                else{
                    if(programShortcomings.deficiency){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.NA;
                        }));
                    }
                    else if(programShortcomings.weakness){
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IV;
                         }));
                    }
                    else if(programShortcomings.concern){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.NGR;
                        }));
                    }
                    
                }      
            }
            //program review type GR
            if(program.programReviewTypeCode === programReviewTypeIds.COMPREHENSIVEVISIT){  
                if(programShortcomings.none){
                     possibleActions.push(actionArray.find(function(action){
                        return action.actionCode === actionCodes.NGR;
                    }));
                }   
                else{
                    if(programShortcomings.deficiency){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCV;
                         }));                        
                    }
                    else if(programShortcomings.weakness){
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IV;
                         }));
                    }                 
                    else if(programShortcomings.concern){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.NGR;
                        }));
                    }
                }      
            }
            //program review type IV
            if(program.programReviewTypeCode === programReviewTypeIds.FOCUSEDVISIT){  
                if(programShortcomings.none){
                     possibleActions.push(actionArray.find(function(action){
                        return action.actionCode === actionCodes.VE;
                    }));
                }   
                else{
                    if(programShortcomings.deficiency){
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCV;
                         }));     
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCR;
                         }));                     
                    }            
                    else if(programShortcomings.weakness){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IV;
                         }));
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IR;
                         }));
                    }
                    else if(programShortcomings.concern){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.VE;
                        }));
                    }                    
                }      
            }
            //program review type IR
            if(program.programReviewTypeCode === programReviewTypeIds.FOCUSEDREPORT){  
                if(programShortcomings.none){
                     possibleActions.push(actionArray.find(function(action){
                        return action.actionCode === actionCodes.RE;
                    }));
                }
                else{
                    if(programShortcomings.deficiency){
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCV;
                         }));                       
                    }
                    else if(programShortcomings.weakness){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IR;
                         }));
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IV;
                         }));                
                    }                  
                    else if(programShortcomings.concern){
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.RE;
                        }));
                    }
                }      
            }
            //program review type SCV
            if(program.programReviewTypeCode === programReviewTypeIds.SHOWCAUSEVISIT){  
                if(programShortcomings.none){
                     possibleActions.push(actionArray.find(function(action){
                        return action.actionCode === actionCodes.SE;
                    }));
                }   
                else{
                    if(programShortcomings.deficiency){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCV;
                         }));    
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.NA;
                        }));            
                    }
                    else if(programShortcomings.weakness){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IV;
                         }));
                    }          
                    else if(programShortcomings.concern){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SE;
                        }));
                    }
                }      
            }
            //program review type SCR
            if(program.programReviewTypeCode === programReviewTypeIds.SHOWCAUSEREPORT){  
                if(programShortcomings.none){
                     possibleActions.push(actionArray.find(function(action){
                        return action.actionCode === actionCodes.SE;
                    }));
                }   
                else{
                    if(programShortcomings.deficiency){
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SCV;
                         }));    
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.NA;
                        }));       
                    }
                    else if(programShortcomings.weakness){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IR;
                         }));
                         possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.IV;
                         }));
                    }                   
                    else if(programShortcomings.concern){
                        possibleActions.push(actionArray.find(function(action){
                            return action.actionCode === actionCodes.SE;
                        }));
                    }
                }      
            }
            return possibleActions;
        }

        function getLatestShortcomingsByResponseType(findingTypes) {
            var results = [];
            for (var i = 0; i < findingTypes.length; i++) {
                var findingType = findingTypes[i];
                if(findingType.statementFindingTypeId === statementFindingTypes.PROGRAMDEFICIENCY || findingType.statementFindingTypeId === statementFindingTypes.PROGRAMWEAKNESS || findingType.statementFindingTypeId === statementFindingTypes.PROGRAMCONCERN){
                    for (var j = 0; j < findingType.findings.length; j++) {
                        var finding = findingType.findings[j];

                        //make a copy to avoid altering the original data referenced by model.section 
                        var filterdResponses = angular.copy(finding.criteria.response); 

                        //remove 7-day response because 7-day response doesn't have any resolution option. It shouldn't affect to the RA selection.
                        Object.keys(filterdResponses).forEach(function(key) { if (key === 'sevenDay') filterdResponses[key] = null;});
                        var responseValues = Object.values(filterdResponses);// should always have the finding.criteria.response object for these statement finding types

                        //var responseValues = Object.values(finding.criteria.response);// should always have the finding.criteria.response object for these statement finding types
                        if(!responseValues.every( function(x){ return x === null; })){//if not every response has a null value
                            var mappedResponses = statementSvc.organizeResponseTypes(finding.criteria.response);
                            var values = Object.values(mappedResponses);
                            for (var x = values.length-1; x >= 0; x--) {
                                var response = values[x];
                                if (response !== null) { //if there is a latest response for this finding then break after pushing the type, if it leads to a shortcoming. Thats what should be used to get the RA
                                    if (response.findingStatusTypeId === findingStatusTypeIds.CHANGED) {//push both remains and changed to see which comes out as the worst                            
                                        results.push({ statementFindingTypeId: response.updatedFindingTypeId });
                                    }
                                    if (response.findingStatusTypeId === findingStatusTypeIds.REMAINS) {
                                        //need to the previous response that was changed?
                                        for(var y = x-1; y >= 0; y--){
                                            var prevReponse = values[y];
                                            if (prevReponse && prevReponse.findingStatusTypeId === findingStatusTypeIds.REMAINS) {
                                                continue;
                                            }
                                            if(prevReponse && prevReponse.findingStatusTypeId === findingStatusTypeIds.CHANGED) {
                                                results.push({ statementFindingTypeId: prevReponse.updatedFindingTypeId });
                                                break;
                                            }else if(y===0){
                                                results.push({ statementFindingTypeId: findingType.statementFindingTypeId });
                                            }                                           
                                        }                                     
                                    }
                            
                                    break;
                                }
                            }
                        }else if(finding){//if no responses for this finding use the findingtype itself
                            results.push({ statementFindingTypeId: findingType.statementFindingTypeId });
                        }
                    } 
                }          
            }
            return returnShortcomings(results);
        }

        function returnShortcomings(findings){
            var results = {concern: false, weakness: false, deficiency: false, none: false}
            
            results.concern = findings.some(function(finding){
                return finding.statementFindingTypeId === statementFindingTypes.PROGRAMCONCERN;
            });

            results.weakness = findings.some(function(finding){
                return finding.statementFindingTypeId === statementFindingTypes.PROGRAMWEAKNESS;
            });

            results.deficiency = findings.some(function(finding){
                return finding.statementFindingTypeId === statementFindingTypes.PROGRAMDEFICIENCY;
            });

            results.none = (!results.concern && !results.weakness && !results.deficiency);

            return results;
        }

        var activate = function () {
            model.data = angular.copy(statementStorageSvc.data);
            model.sectionIndex = statementStorageSvc.getSectionIndex(model.data.statement, currentSection);
            model.section = model.data.statement.statementDetailDtos[model.sectionIndex];

            model.data = angular.copy(statementStorageSvc.data);
            model.isReport = model.data.currentReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT;
            actionSvc.getActions().then(function(data){
                model.possibleActions = getPossibleActions(data);
                model.isDataReady = true;
            });
            model.isDraftStatement = model.data.statement.statementTypeId === statementTypeIds.DRAFT;
            model.pevRecommendedAction = statementStorageSvc.getPEVRA(model.section.programId);
        }();
    };

    module.controller('editRecommendedActionCtrl', editRecommendedActionCtrl);

}(angular.module('statement')));