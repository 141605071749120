(function (module) {
    var ssoCtrl = function (oauth, $http, $state, $stateParams, $window, currentUser, $cookies, environmentSvc) {
        var model = this;
        var accessToObj = {};
        var clientUrl = '';
        var urlWsAPI = "/webapi/odata/RequestSsoWS";
        var urlD2lAPI = "/webapi/odata/RequestSsoD2L";
        var urlAmsRptAPI = "/webapi/odata/RequestSsoAmsRpt";
        var urlAbetPulicAPI = "/webapi/odata/RequestSsoWP";
        var urlAbetConcurHmacAPI = "/webapi/odata/RequestSsoConcurHmac";
        var urlAbetConcurSamlAPI = "/webapi/odata/RequestSsoConcurSaml";

        // Development
        //var urlConcurACS = "http://localhost:51394/SAML/AssertionConsumerService.aspx?binding=urn%3aoasis%3anames%3atc%3aSAML%3a2.0%3abindings%3aHTTP-POST"; 
        //var concurRelayState = "7f764a82-f723-4405-bb96-2edba15827e6";
        // Production
        var urlConcurACS = "https://www-us.api.concursolutions.com/sso/saml2/V1/acs/";
        var concurRelayState = "https://www.concursolutions.com/home.asp";

        var urlBoardEffectAPI = "/webapi/odata/RequestSsoBoardEffect";
        //var urlAmsPubAPI = "/webapi/odata/RequestSsoAmsPub";

        var hlClientUrl = 'https://abet.sandbox.kavi.com/higherlogic/ws/module.php/saml/sp/saml2-acs.php/saml';
        var d2lClientUrl = 'https://abet.brightspace.com/d2l/lp/auth/login/ssoLogin.d2l';
        var amsrptUrl = 'https://amsrpt.abet.org/';
        var abetPublicUrl = 'https://www.abet.org/myabet-profile/';
        //var d2lClientUrl = 'https://abettest.brightspace.com/d2l/lp/auth/login/ssoLogin.d2l';
        var boardEffectUrl = 'https://abet.boardeffect.com/sso/jwt'; 

        var dev_teamSpaceUrl = 'http://localhost:3000/';
        var stage_teamSpaceUrl = 'https://stage.abet.org/teamspaces/';
        var prod_teamSpaceUrl = 'https://amspub.abet.org/teamspaces/';

        if ($stateParams.accessTo === 'd2l')
            requestD2l(accessToObj);
        else if ($stateParams.accessTo === 'amsrpt')
            requestAmsRpt(accessToObj);
        else if ($stateParams.accessTo === 'workspace')
            requestToWs(accessToObj);
        else if ($stateParams.accessTo === 'abetpublic')
            requestToAbetWP(accessToObj);
        else if ($stateParams.accessTo === 'concur')
            requestToConcur(accessToObj);
        else if ($stateParams.accessTo === 'logout')
            requestToLogout(accessToObj)
        else if ($stateParams.accessTo === 'close')
            requestToClose(accessToObj)
        else if ($stateParams.accessTo === 'boardeffect')
            requestBoardEffect(accessToObj);
        else if ($stateParams.accessTo === 'external')
            requestExternal(accessToObj);
        else if ($stateParams.accessTo === 'amspub')
            requestToAbetWP(accessToObj);

        function requestBoardEffect() {
            oauth.requestSso(urlBoardEffectAPI).then(function (data) {
                $window.location.href = boardEffectUrl + '?jwt=' + data.data;
            });
        }

        function requestD2l() {
            oauth.requestSso(urlD2lAPI).then(function (data) {
                $window.location.href = d2lClientUrl + '?guid=' + data.data + '&username=' + currentUser.profile.username
            });
        }

        function requestAmsRpt() {
            oauth.requestSso(urlAmsRptAPI).then(function (data) {
                if (data) {
                    $cookies.put('AuthToken', data.data, { domain: '.abet.org' });
                    $window.location.href = amsrptUrl;
                }
            });
        }

        function requestToAbetWP() {
            oauth.requestSso(urlAbetPulicAPI).then(function (data) {
                if (data) {
                    $cookies.put('sharedAuth', data.data, { domain: '.abet.org' });
                    $window.location.href = abetPublicUrl;
                }
            });
        }

        function requestToWs() {
            oauth.requestSso(urlWsAPI,accessToObj).then(function (data) {
                var form = $('<form></form>');
                form.attr("method", "post");
                form.attr("action", hlClientUrl);

                var field = $('<input></input>');

                field.attr("type", "hidden");
                field.attr("name", "SAMLResponse");
                field.attr("value", data.data);

                form.append(field);
                $(document.body).append(form);
                form.submit();
            });
        }

        function requestToConcur() {
            const handleError = (error) => {
                console.error(error);
                ssoConcurHmac(accessToObj)
                    .catch(error =>
                        console.error(error)
                    );
            }

            try {
                ssoConcurSaml(accessToObj)
                    .catch(handleError)
            } catch (error) {
                handleError(error);
            }
        }

        function ssoConcurHmac() {
            return oauth.requestSso(urlAbetConcurHmacAPI).then(function (data) {
                if (data) {
                    $window.location.href = data.data;
                }
            });
        }

        function ssoConcurSaml() {
            return oauth.requestSso(urlAbetConcurSamlAPI).then(function (data) {
                if (data) {
                    // Post SAML assertion to Concur Assertion Consumer Service
                    var form = document.createElement('form');
                    form.setAttribute('action', urlConcurACS);
                    form.setAttribute('method', 'post');
                    //form.setAttribute('enctype', 'text/plain');
                    form.setAttribute('hidden', true);
                    // SAML assertion
                    var samlResponse = document.createElement('input');
                    samlResponse.setAttribute('type', 'hidden');
                    samlResponse.setAttribute('name', 'SAMLResponse');
                    samlResponse.setAttribute('value', data.data);
                    form.appendChild(samlResponse)
                    //// Relay state (to redirect authenticated users to)
                    //var relayState = document.createElement('input');
                    //relayState.setAttribute('type', 'hidden');
                    //relayState.setAttribute('name', 'RelayState');
                    //relayState.setAttribute('value', concurRelayState);
                    //form.appendChild(relayState)
                    // Submit hidden form containing SAML message
                    document.body.appendChild(form);
                    form.submit();
                }
            });
        }

        function requestToLogout() {
            // TODO: Implement full SAML single-logout with return assertion (on server if digital signature required)
            oauth.logout().then(function () {
                $state.go('logout');
            });
        }

        function requestToClose() {
            // Assuming SSO destination was opened in a new window/tab, close the current tab and leave use logged into AMS 
            close();
        }

        //function requestToAmsPub() {
        //    oauth.requestSso(urlAmsPubAPI).then(function (data) {
        //        if (data) {
        //            $window.location.href = 'http://localhost:4200/team-space/';
        //        }
        //    });
        //}

        function requestExternal() {
            const loc = new URL(window.location.toString());
            const referrer = loc.searchParams.get('referrer');

            const dest = new URL(referrer);
            dest.searchParams.delete('referrer');
            dest.searchParams.set('jwt', currentUser.profile.token);

            $window.location.href = dest.toString();
        }

    };
    module.controller('ssoCtrl', ssoCtrl);

}(angular.module('security')));