(function (module) {
    var rrReadinessCtrl = function ($state, readinessSvc, criteriaTypes, readinessValidationSvc) {

        var model = this;

        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.openDocumentUploadInstructions = readinessSvc.openDocumentUploadInstructions;
        model.requestLink = 'https://www.abet.org/wp-content/uploads/2018/07/A039-Information-for-Programs-Requesting-Readiness-Review-3-12-2018.pdf';

        var activate = function () {
            model.data = readinessSvc.data;
            angular.forEach(model.data.selectedRR.programs, function (program) {
                program.readinessReview = program.readinessReview || {}; 
                if (!Array.isArray(program.readinessReview.criteria))
                    program.readinessReview.criteria = [];
            });
            readinessValidationSvc.invokeValidation();
            model.isDataReady = true;
        }();
    }

    module.controller('rrReadinessCtrl', rrReadinessCtrl);

}(angular.module('readinessReview')));