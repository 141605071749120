(function (module) {

    var templateRoot = "/Apps/oss/templates/";

    module.directive('selfStudy', function (alertSvc, documentSvc, readinessSvc, criteriaTypes, readinessStatuses, programReviewSvc, userReviewSvc,
        $filter, helperSvc, currentUser, reviewTeamMemberSvc, messageSvc, messageTemplateTypes, $state, $uibModal,
        currentOrganization, currentOrganizationHelper, currentUserHelper, selfStudyUploadOptionTypes, readinessValidationSvc, $q, programReviewTypeIds, eventSvc, reviewValidationSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'selfStudy.html',
            scope: {
                programs: '=',
                readiness: '@?',
                errors: '='
            },
            link: function (scope, elem, attrs) {
                scope.selectedId = null;
                scope.activeProgram = null;
                scope.activeProgramFullName = null;
                scope.rrMode = attrs.readiness ? (attrs.readiness.toLowerCase() == 'true') : false;
                scope.isReadOnly = false;
                scope.institutionView = true;
                scope.switchView = function () { scope.institutionView = !scope.institutionView; scope.isReadOnly = true; }; // For testing
                scope.noProgramsMsg = scope.rrMode ? 'Please enter programs from the \'Program Information\' tab to proceed' : null;
                scope.testFileStorage = null;
                scope.testFileArrStorage = null;
                scope.isOpen = false;
                scope.isAdmin = currentUser.profile.userTasks.some(function (task) { return task == 'admin'; });
                scope.isTeamChair = false;
                scope.isPevOrProgramObserver = false;
                scope.isReviewer = false;
                scope.cannotSwitchPrograms = false;
                scope.hasAccess = true;
                scope.activeProgramReview = null;
                scope.option = {
                    single: null,
                    multi: null,
                    allowChange: false
                };


                scope.toggleOpen = function () {
                    scope.isOpen = !scope.isOpen;
                };
                
                scope.displayedCriteria = {
                    leftSide: [],
                    leftSideUploadAfterSubmission: [],
                    rightSide: [],
                    rightSideUploadAfterSubmission: []
                };

                scope.requiredNumOfUploads = {
                    readiness: 3,
                    selfstudy: 13
                };

                scope.leftSideFilter = function (criterion) {
                    var leftSideTypes = [
                            criteriaTypes.BACKGROUND,
                            criteriaTypes.CRITERIA,
                            criteriaTypes.INTERIMREPORT,
                            criteriaTypes.TERMINATIONPLAN,
                            criteriaTypes.MASTERSGENERALCRITERIA,
                            criteriaTypes.SELFSTUDY
                    ];

                    var included = leftSideTypes.indexOf(criterion.criteriaTypeId) > -1;
                    if (!scope.institutionView) {
                        included = included && (scope.allowUploadsAfterSubmit(criterion) || criterion.stream_id || (criterion.files && criterion.files.length > 0));
                    }
                    return included;
                };

                scope.rightSideFilter = function (criterion) {
                    var rightSideTypes = [criteriaTypes.APPENDIX, criteriaTypes.TRANSCRIPT, criteriaTypes.OPTIONAL, criteriaTypes.ADDITIONAL];
                    var included = rightSideTypes.indexOf(criterion.criteriaTypeId) > -1;
                    if (!scope.institutionView) {
                        included = included && (scope.allowUploadsAfterSubmit(criterion) || criterion.stream_id || (criterion.files && criterion.files.length > 0));
                    }
                    return included;
                };

                var allowUploadsAfterSubmit = [criteriaTypes.ADDITIONAL];
                scope.allowUploadsAfterSubmit = function (criterion) {
                    return allowUploadsAfterSubmit.indexOf(criterion.criteriaTypeId) > -1;
                };

                scope.disallowUploadsAfterSubmit = function (criterion) {
                    return (scope.isAdmin && scope.isSubmitted) || allowUploadsAfterSubmit.indexOf(criterion.criteriaTypeId) < 0;
                };

                var disallowVolunteerView = [criteriaTypes.ADDITIONAL];
                scope.disallowVolunteerView = function (criterion) {
                    return disallowVolunteerView.indexOf(criterion.criteriaTypeId) === -1;
                };

                scope.allowMultipleFiles = function (criterion) {
                    var multipleFileTypes = [criteriaTypes.OPTIONAL, criteriaTypes.ADDITIONAL];
                    return multipleFileTypes.indexOf(criterion.criteriaTypeId) > -1;
                };

                scope.isOptional = function (criterion) {
                    var disciplines = scope.rrMode ? scope.activeProgram.disciplines : scope.activeProgramReview.programReviewDisciplineDtos;

                    return readinessSvc.isCriterionOptional(criterion, scope.rrMode, disciplines);
                };

                scope.isUploadVisible = function () {
                    return scope.activeProgram && (scope.rrMode || scope.institutionView || scope.isSubmitted)
                };

                scope.isSubmitButtonVisible = function () {
                    return !scope.rrMode && !scope.isSubmitted && scope.institutionView && scope.activeProgram;
                };

                scope.getFullProgramName = function (program) {
                    return program ? program.programName + ' (' + program.degreeCode + ')' : '';
                };

                scope.selectProgram = function (program) {
                    if (program) {
                        scope.selectedId = program.programName + program.degreeCode; //program.SOME_ID (change isSelected function when this changes)
                        scope.activeProgram = program;

                        if (scope.activeProgram) {
                            scope.activeProgramFullName = scope.getFullProgramName(scope.activeProgram);
                            // Setting submission details here appears to be redundant but leaving until we can thoroughly test RR
                            scope.setSubmissionDetails();
                            scope.setDisplayedCriteria();
                            selectProgramReview(scope.activeProgram.programReviewId);
                            scope.setUploadOption();
                        } else {
                            scope.clearDisplayedCriteria();
                        }
                    }                   
                };

                scope.clearDisplayedCriteria = function () {
                    scope.displayedCriteria.leftSide = [];
                    scope.displayedCriteria.leftSideAfterSubmission = [];
                    scope.displayedCriteria.rightSide = [];
                    scope.displayedCriteria.rightSideAfterSubmission = [];
                }

                scope.setDisplayedCriteria = function () {
                    var criteria = scope.activeProgram.readinessReview.criteria;
                    var leftSideCriteria = criteria.filter(scope.leftSideFilter);
                    scope.displayedCriteria.leftSide = leftSideCriteria.filter(scope.disallowUploadsAfterSubmit);
                    scope.displayedCriteria.leftSideAfterSubmission = leftSideCriteria.filter(scope.allowUploadsAfterSubmit);

                    var rightSideCriteria = criteria.filter(scope.rightSideFilter);
                    scope.displayedCriteria.rightSide = rightSideCriteria.filter(scope.disallowUploadsAfterSubmit);
                    scope.displayedCriteria.rightSideAfterSubmission = rightSideCriteria.filter(scope.allowUploadsAfterSubmit);

                    // This code appears to prevent TCs, PEVs, and Editors from seeing "additional uploads" which doesn't make sense given
                    // that they will need access to documents uploaded after self study submission.
                    //
                    ////if (scope.isPEV || scope.isTeamChair || scope.isEditor || scope.isAdjunct) {
                    ////    scope.displayedCriteria.leftSide = scope.displayedCriteria.leftSide.filter(scope.disallowVolunteerView);
                    ////    scope.displayedCriteria.leftSideAfterSubmission = scope.displayedCriteria.leftSideAfterSubmission.filter(scope.disallowVolunteerView);
                    ////    scope.displayedCriteria.rightSide = scope.displayedCriteria.rightSide.filter(scope.disallowVolunteerView);
                    ////    scope.displayedCriteria.rightSideAfterSubmission = scope.displayedCriteria.rightSideAfterSubmission.filter(scope.disallowVolunteerView);
                    ////}

                    scope.displayedCriteria.total = scope.displayedCriteria.leftSide.length + scope.displayedCriteria.rightSide.length;
                }

                scope.criteriaTypes = criteriaTypes;
                scope.getCriteriaFullName = readinessSvc.getCriteriaFullName;

                function selectProgramReview(programReviewId) {
                    if (!scope.rrMode) {
                        scope.activeProgramReview = programReviewSvc.findProgramReview(programReviewId);
                    }
                }

                scope.setSubmissionDetails = function () {
                    if (scope.rrMode) {
                        scope.isSubmitted = readinessSvc.data.selectedRR.readinessProcessTrackingDtos.some(function (dto) {
                            return dto.readinessStatusId > readinessStatuses.WAITING_FOR_READINESS_REVIEW_SUBMISSION;
                        });
                    } else {
                        scope.isSubmitted = userReviewSvc.data.currentReviewTeam && userReviewSvc.data.currentReviewTeam.selfStudySubmittedTimestamp != null; // only applies to self-study
                        scope.submittedDate = scope.isSubmitted ? helperSvc.formatDate(userReviewSvc.data.currentReviewTeam.selfStudySubmittedTimestamp, false, true).toLocaleDateString() : "";
                    }
                    if (scope.isSubmitted) scope.option.allowChange = false;
                }

                var cannotChangeUploadOptionFor = [
                    programReviewTypeIds.BODFOCUSED,
                    programReviewTypeIds.FOCUSEDREPORT,
                    programReviewTypeIds.FOCUSEDVISIT,
                    programReviewTypeIds.FOCUSEDSHOWCAUSE,
                    programReviewTypeIds.SHOWCAUSEREPORT,
                    programReviewTypeIds.SHOWCAUSEVISIT,
                    programReviewTypeIds.EXCOMFOCUSEDREPORT,
                    programReviewTypeIds.EXCOMFOCUSEDVISIT,
                    programReviewTypeIds.TERMINATIONREPORT,
                    programReviewTypeIds.TERMINATIONVISIT,
                ];

                scope.getUploadOptionType = function (program) {
                    var uploadOptionType = readinessSvc.getUploadOptionType(program);
                    switch (uploadOptionType) {
                        case selfStudyUploadOptionTypes.SINGLEDOCUMENT:
                            return "Single";
                            break;
                        case selfStudyUploadOptionTypes.MULTIPLEDOCUMENTS:
                            return "Multiple";
                            break;
                        default:
                            return "Not set";
                    }
                };
                scope.setUploadOption = function () {
                    var uploadOptionType = readinessSvc.getUploadOptionType(scope.activeProgram);
                    switch (uploadOptionType) {
                        case selfStudyUploadOptionTypes.SINGLEDOCUMENT:
                            scope.option = {
                                single: true,
                                multi: false
                            };
                            break;
                        case selfStudyUploadOptionTypes.MULTIPLEDOCUMENTS:
                            scope.option = {
                                single: false,
                                multi: true
                            };
                            break;
                        default:
                            scope.option = {
                                single: null,
                                multi: null
                            };
                    }

                    var programReviewAllowsChange = scope.rrMode || 
                                                    (scope.activeProgramReview &&  
                                                     cannotChangeUploadOptionFor.indexOf(scope.activeProgramReview.programReviewTypeCode) < 0);

                    scope.option.allowChange = scope.institutionView && !scope.isSubmitted && programReviewAllowsChange && (scope.option.single || scope.option.multi);
                }

                scope.isSelected = function (program) {
                    return scope.selectedId == program.programName + program.degreeCode; //program.SOME_ID (change selectProgram function when this changes
                };

                scope.allowPreview = function (criterion) {
                    return (/.+\.pdf$/i).test(criterion.originalFileName);
                }

                scope.openPDFModal = function (streamId, title) {
                    var isInline = true;
                    alertSvc.openPDFModal(documentSvc.getFileUrl(streamId, isInline), title);
                };

                function formatOrganizationName(name) {
                    // Replace spaces with dashes.
                    var formattedName = name;
                    // Remove common words.
                    var removals = [
                        /(university of)+/gi,
                        /(university)+/gi,
                        /(universidad del)+/gi,
                        /(universidad de los)+/gi,
                        /(universidad de)+/gi,
                        /(universidad)+/gi,
                        /(autonoma)+/gi,
                        /(college)+/gi,
                        /(state)+/gi,
                        /(\bof\b)+/gi,
                        /(\bdel\b)+/gi,
                        /(\bde los\b)+/gi,
                        /(\bde\b)+/gi,
                        /(\bat\b)+/gi,
                        /(\bfor\b)+/gi,
                        /(\bthe\b)+/gi,
                        /([~!@#$%^&*\-()_+=`{}\[\]\|\\:;'"<>,.\/?])+/g, // special characters
                    ];
                    removals.forEach(function (removal) {
                        formattedName = formattedName.replace(removal, '');
                    });
                    // Remove spaces
                    formattedName = formattedName.trim().replace(/(\s)+/g, '');

                    return formattedName;
                }

                function formatAppendixName(name) {
                    var formattedName = '';

                    var words = name.split(' ');
                    if (words.length > 1) {
                        formattedName = 'appendix' + words[1];
                    } else {
                        formattedName = name;
                    }
                    formattedName = formattedName.trim()
                        .replace(/(\s)+/g, '')
                        .replace(/([~!@#$%^&*\-()_+=`{}\[\]\|\\:;'"<>,.\/?])+/g, '');
                    return formattedName.toLowerCase();
                }

                scope.getVolunteerViewCriterionFilename = function (criterion) {
                    var fileSuffix = '';
                    if (criterion.criteriaTypeId == criteriaTypes.SELFSTUDY) {
                        fileSuffix = 'report';
                    } else if (criterion.criteriaTypeId == criteriaTypes.BACKGROUND) {
                        fileSuffix = 'bkgd';
                    } else if (criterion.criteriaTypeId == criteriaTypes.INTERIMREPORT) {
                        fileSuffix = 'ir';
                    } else if (criterion.criteriaTypeId == criteriaTypes.TERMINATIONPLAN) {
                        fileSuffix = 'tp';
                    } else if (criterion.criteriaName.indexOf('Program Criteria') > -1) {
                        fileSuffix = 'pc';
                    } else if (criterion.criteriaTypeId == criteriaTypes.MASTERSGENERALCRITERIA) {
                        // Hard coding master criteria descriptions for now until we can come up with a better naming convention
                        if (criterion.criteriaDescription.toLowerCase().indexOf('students and curriculum') > -1) {
                            fileSuffix = 'ms_sc';
                        } else if (criterion.criteriaDescription.toLowerCase().indexOf('program educational objectives and student outcomes') > -1) {
                            fileSuffix = 'ms_peo_so';
                        } else if (criterion.criteriaDescription.toLowerCase().indexOf('program quality') > -1) {
                            fileSuffix = 'ms_pq';
                        } else if (criterion.criteriaDescription.toLowerCase().indexOf('faculty') > -1) {
                            fileSuffix = 'ms_fy';
                        } else if (criterion.criteriaDescription.toLowerCase().indexOf('facilities') > -1) {
                            fileSuffix = 'ms_fs';
                        } else if (criterion.criteriaDescription.toLowerCase().indexOf('institutional support') > -1) {
                            fileSuffix = 'ms_is';
                        } else {
                            fileSuffix = 'ms';
                        }
                    } else {
                        fileSuffix = 'cr' + criterion.criteriaId;
                    }

                    return scope.formattedOrganizationName + '_oss_' + fileSuffix + getFileType(criterion.originalFileName);
                }

                scope.getVolunteerViewAppendixFilename = function (criterion) {
                    return scope.formattedOrganizationName + '_oss_' + formatAppendixName(criterion.criteriaName) + getFileType(criterion.originalFileName);
                }

                scope.getVolunteerViewOptionalAppendixFilename = function (criterion, index) {
                    return scope.formattedOrganizationName + '_oss_optional' + (index + 1) + getFileType(criterion.originalFileName);
                }

                function getFileType(fileName) {
                    return (/\..+$/i).exec(fileName);
                }

                scope.getVolunteerViewMergedFilename = function () {
                    return scope.formattedOrganizationName + '_oss.pdf';
                }

                scope.getVolunteerViewZippedFilename = function () {
                    // Call getVolunteerViewMergedFilename to make sure filenames are consistent.
                    return scope.getVolunteerViewMergedFilename().replace(/\.pdf$/, '') + '.zip';
                }

                scope.getErrorIndex = readinessSvc.getProgramKey;

                scope.selectAllCriteria = function (value) {
                    var criteria = scope.displayedCriteria.leftSide;
                    selectAll(criteria, value);
                }

                scope.selectAllAppendicies = function (value) {
                    var criteria = scope.displayedCriteria.rightSide;
                    selectAll(criteria, value);
                }

                function selectAll(criteria, value) {
                    angular.forEach(criteria, function (criterion) {
                        if (criterion.stream_id) {
                            criterion.isSelected = value;
                        } else if (Array.isArray(criterion.files) && criterion.files.length > 0) {
                            angular.forEach(criterion.files, function (file) {
                                file.isSelected = value;
                            });
                        }
                    });
                }

                scope.areDocumentsSelected = function () {
                    var getSelected = true;
                    var selectedDocumentIds = getDocumentIds(getSelected);
                    return scope.option.single ? selectedDocumentIds.length > 0 : selectedDocumentIds.length > 1;
                }

                scope.isPrintEnabled = function () {
                    var selectedDocumentIds = getDocumentIds();
                    return selectedDocumentIds.length > 1;
                }

                scope.mergeDocuments = function () {
                    var getSelected = true;
                    var selectedDocumentIds = getDocumentIds(getSelected);
                    var mergedFilename = scope.getVolunteerViewMergedFilename();
                    documentSvc.mergeDocuments(selectedDocumentIds, mergedFilename);
                }

                scope.printAll = function () {
                    var selectedDocumentIds = getDocumentIds();
                    var mergedFilename = scope.getVolunteerViewMergedFilename();
                    documentSvc.printDocuments(selectedDocumentIds, mergedFilename);
                }
                
                function getDocumentIds(getSelected) {
                    var selectedDocumentIds = [];
                    if (scope.activeProgram) {
                        angular.forEach(scope.activeProgram.readinessReview.criteria, function (criterion) {
                            if (criterion.stream_id != null && (criterion.isSelected || !getSelected)) {
                                selectedDocumentIds.push(criterion.stream_id);
                            }
                            if (Array.isArray(criterion.files) && criterion.files.length > 0) {
                                angular.forEach(criterion.files, function (file) {
                                    if (file.isSelected || !getSelected) {
                                        selectedDocumentIds.push(file.stream_id);
                                    }
                                });
                            }
                        });
                    }
                    return selectedDocumentIds;
                }

                scope.downloadSelected = function () {
                    downloadFiles();
                }

                scope.downloadAll = function () {
                    var downloadAll = true;
                    downloadFiles(downloadAll);
                }

                function downloadFiles(downloadAll) {
                    var getSelected = !downloadAll;
                    var files = getFiles(getSelected);
                    if (files.length === 1) {
                        try {
                            var mergedFilename = scope.getVolunteerViewMergedFilename();
                            documentSvc.downloadDocument(files[0].streamId, files[0].fileName || mergedFilename);
                            console.log('Document downloaded');
                        } catch (error) {
                            console.log(error);
                            alertSvc.addAlertWarning('Files could not be downloaded at this time.');
                        }
                    } else {
                        var zippedFileName = scope.getVolunteerViewZippedFilename();
                        documentSvc.downloadDocuments(files, zippedFileName).then(
                            function () {
                                console.log('Documents downloaded');
                            },
                            function (error) {
                                console.log(error);
                                alertSvc.addAlertWarning('Files could not be downloaded at this time.');
                            });
                    }
                }

                function getFiles(getSelected) {
                    var files = [];

                    angular.forEach(scope.displayedCriteria.leftSide, function (criterion) {
                        extractFiles(criterion, scope.getVolunteerViewCriterionFilename);
                    });

                    angular.forEach(scope.displayedCriteria.rightSide, function (criterion) {
                        extractFiles(criterion, scope.getVolunteerViewAppendixFilename, scope.getVolunteerViewOptionalAppendixFilename);
                    });

                    return files;
                    
                    function extractFiles(criterion, getFileNameFunc, getOptionalFileNameFunc) {
                        if (!getOptionalFileNameFunc) getOptionalFileNameFunc = getFileNameFunc;

                        if (criterion.stream_id != null && (criterion.isSelected || !getSelected)) {
                            files.push({
                                streamId: criterion.stream_id,
                                fileName: getFileNameFunc(criterion)
                            });
                        }
                        if (Array.isArray(criterion.files) && criterion.files.length > 0) {
                            for (var index = 0; index < criterion.files.length; index++) {
                                var file = criterion.files[index];
                                if (file.isSelected || !getSelected) {
                                    files.push({
                                        streamId: file.stream_id,
                                        fileName: getOptionalFileNameFunc(file, index)
                                    });
                                }
                            }
                        }
                    }
                }

                function programsNotReady(programs) {
                    if (!programs || !Array.isArray(programs) || programs.length === 0) return false;  
                    return !programs || !Array.isArray(programs) || programs.length === 0 ||
                           programs.some(function (program) {
                               var criteria = program.readinessReview.criteria;
                               if (!criteria || ! criteria.length) return true;
                               return criteria.some(function (criterion) {
                                   var disciplines;
                                   if (scope.rrMode) {
                                       disciplines = program.disciplines;
                                   } else {
                                       var programReview = programReviewSvc.findProgramReview(program.programReviewId);
                                       disciplines = programReview.programReviewDisciplineDtos;
                                   }
                                   var required = !readinessSvc.isCriterionOptional(criterion, scope.rrMode, disciplines);                                     
                                   var hasFiles = (Array.isArray(criterion.files) && criterion.files.length > 0) || (criterion.stream_id != null);
                                   return required && !hasFiles;
                               });
                           });
                }

                scope.readyToSubmit = function () {
                    return !(programsNotReady(scope.programs) || programsNotReady(scope.unlistedPrograms));
                }

                scope.disableSelfStudySubmit = function () {
                    return !scope.hasSubmitPermission || !scope.readyToSubmit();
                }

                scope.submitSelfStudies = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/oss/templates/selfStudySubmit.html',
                        size: 'lg',
                        controller: 'selfStudySubmitCtrl',
                        resolve: {
                            parent: function () {
                                return scope;
                            }
                        }
                    });
                }

                scope.updateSelfStudyConfirmedByTC = function (program) {
                    var programReview = programReviewSvc.findProgramReview(program.programReviewId);

                    programReview.selfStudyConfirmedByTC = !programReview.selfStudyConfirmedByTC;

                    program.selfStudyConfirmedByTC = programReview.selfStudyConfirmedByTC;

                    programReviewSvc.updateOdata(programReview).then(
                        function () {
                            if (programReview.selfStudyConfirmedByTC === true)
                                alertSvc.addAlertSuccess("PEV now has access to the " + scope.activeProgram.programName + " self study.");
                            else
                                alertSvc.addAlertSuccess("PEV no longer has access to the " + scope.activeProgram.programName + " self study.");
                            reviewValidationSvc.invokeValidation()
                        },
                        function () {
                            alertSvc.addAlertWarning(scope.activeProgram.programName + " self study confirmation could not be submitted at this time.");
                        });
                }

                scope.isSelfStudyConfirmedByTC = function (program) {
                    var programReview = programReviewSvc.findProgramReview(program.programReviewId);
                    return programReview.selfStudyConfirmedByTC === true;
                }

                scope.computeProgress = function () {
                    var progress = {
                        programsCount: 0,
                        programsCompletedCount: 0,
                        programProgress: {}
                    };

                    if (Array.isArray(scope.programs) && scope.programs.length > 0) {
                        angular.forEach(scope.programs, function (program) {
                            progress.programsCount++;
                            if (program.readinessReview && Array.isArray(program.readinessReview.criteria)) {
                                var programProgress = {
                                    criteriaRequiredCount: 0,
                                    criteriaCompletedCount: 0
                                }
                                angular.forEach(program.readinessReview.criteria, function (criterion) {
                                    var disciplines;
                                    if (scope.rrMode) {
                                        disciplines = program.disciplines;
                                    } else {
                                        var programReview = programReviewSvc.findProgramReview(program.programReviewId);
                                        disciplines = programReview.programReviewDisciplineDtos;
                                    }
                                    if (!readinessSvc.isCriterionOptional(criterion, scope.rrMode, disciplines)) {
                                        programProgress.criteriaRequiredCount++;
                                        if (criterion.stream_id || (Array.isArray(criterion.files) && criterion.files.length > 0)) {
                                            programProgress.criteriaCompletedCount++;
                                        }
                                    }
                                });
                                if (programProgress.criteriaRequiredCount > 0 && programProgress.criteriaRequiredCount === programProgress.criteriaCompletedCount) {
                                    progress.programsCompletedCount++;
                                }
                                progress.programProgress[scope.getErrorIndex(program)] = programProgress;
                            }
                        });
                    }
                    scope.progress = progress;
                }

                scope.canSelectProgram = function (program) {
                    // Reviewers can only 
                    return !scope.isReviewer ||
                        (program && program.readinessReview && program.readinessReview.reviewer && program.readinessReview.reviewer.personId == currentUser.profile.personId);
                }

                scope.selectSingleOption = function () {
                    alertSvc.confirm("<p>You are about to select the <strong><em>Single Document Upload</em></strong> option. You may change to the multiple document upload option later from this page.</p>", function () {
                        var singleDocument = true;
                        scope.selectUploadOption(singleDocument);
                    });
                };

                scope.selectMultiOption = function () {
                    alertSvc.confirm("<p>You are about to select the <strong><em>Multiple Document Upload</em></strong> option. You may change to the single document upload option later from this page.</p>", function () {
                        var singleDocument = false;
                        scope.selectUploadOption(singleDocument);
                    });
                };

                scope.selectUploadOption = function (singleDocument) {
                    if (!scope.activeProgram) return;

                    readinessSvc.initializeReadinessReview(scope.rrMode, scope.activeProgram, singleDocument).then(function (data) {
                        if (scope.rrMode) {
                            readinessSvc.update(readinessSvc.data.selectedRR, true).then(function () {
                                postInitialization(singleDocument);
                            });
                        } else {
                            var programReview = programReviewSvc.findProgramReview(scope.activeProgram.programReviewId);
                            var selfStudyCriterion = {
                                "selfStudyCriteriaId": 0,
                                "programReviewId": programReview.programReviewId,
                                "criteriaId": singleDocument ? programReviewSvc.SELFSTUDYCRITERIAID : programReviewSvc.BACKGROUNDCRITERIAID,
                                "originalFileName": null,
                                "stream_id": null
                            };
                            programReview.selfStudyCriteriaDtos.push(selfStudyCriterion);
                            programReviewSvc.updateOdata(programReview).then(function () {
                                postInitialization(singleDocument);
                            });
                        }
                     });
 
                    function postInitialization(singleDocument) {
                        scope.setDisplayedCriteria();
                        scope.setUploadOption();
                        readinessValidationSvc.invokeValidation();
                    }
                }

                scope.changeUploadOption = function () {
                    var criteria = scope.activeProgram && scope.activeProgram && scope.activeProgram.readinessReview && scope.activeProgram.readinessReview.criteria;

                    if (!criteria || !(scope.option.single || scope.option.multi)) return;

                    var confirmationMessage = 
                        "You are about to change this program to use the " +
                        (scope.option.single ? 
                            "<strong><em>Upload Mulitple Documents</em></strong> option. Separate documents for each criteria and appendix will be required. " :
                            "<strong><em>Upload Single Document</em></strong> option. One self study document including all criteria and appendices will be required. ") +
                        "<br/><br/>All current uploads will be removed. Are you sure you want to proceed?";

                    alertSvc.confirm(confirmationMessage, doChangeUploadOption);

                    function doChangeUploadOption() {
                        var singleDocument = !scope.option.single;
                        var deleteAllFiles = [];
                        criteria.forEach(function (criterion) {
                            var deleteFilePromise = criterion.stream_id ? documentSvc.deleteFile(criterion.stream_id) : null;
                            deleteAllFiles.push(deleteFilePromise);
                        });
                        $q.all(deleteAllFiles).then(function () {
                            scope.activeProgram.readinessReview.criteria = [];
                            if (!scope.rrMode) {
                                // Delete self-study criteria objects as well...
                                var programReview = programReviewSvc.findProgramReview(scope.activeProgram.programReviewId);
                                angular.forEach(programReview.selfStudyCriteriaDtos, function (selfStudyCriterion) {
                                    selfStudyCriterion.isDeleted = true;
                                });
                            };
                            scope.selectUploadOption(singleDocument);
                        });
                    }
                }

                var activate = function () {
                    if (scope.rrMode) {
                        scope.isReadOnly = readinessSvc.isReadOnly();
                        scope.institutionView = !scope.isReadOnly;
                        scope.isReviewer = readinessSvc.isReviewer();
                    } else {

                        scope.isTeamChair = userReviewSvc.isChairPerson(userReviewSvc.data.currentUserReviewTeam);
                        scope.isPevOrProgramObserver = userReviewSvc.isPevOrProgramObserver(userReviewSvc.data.currentUserReviewTeam);
                        scope.isEditor = userReviewSvc.isEditor(userReviewSvc.data.currentUserReviewTeam);
                        scope.isAdjunct = userReviewSvc.isAdjunct(userReviewSvc.data.currentUserReviewTeam);
                        // Hide programs with TR reviews but no accreditation end date from TCs, PEVs.
                        try {
                            if (scope.isTeamChair || scope.isPevOrProgramObserver) {
                                var filteredPrograms = scope.programs.filter(function (program) {
                                    return programReviewSvc.data.programs.some(function (programReview) {
                                        return programReview.programReviewId === program.programReviewId &&
                                            (programReview.programReviewTypeCode.toLowerCase() != 'tr' ||
                                                programReview.accreditationEndDate != null);
                                    });
                                });
                                // Redirect to start page if there is nothing to see here (i.e. user got here by direct link but there are no programs to view)
                                if (!filteredPrograms.length) 
                                    $state.go($state.current.name, { view: userReviewSvc.slugs.TEAM });
                                // Avoid resetting array if we don't have to so we don't need to test all possible side effects.
                                if (filteredPrograms.length != scope.programs.length) scope.programs = filteredPrograms;
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    }

                    var programsUnderReviewCount = 0;
                    angular.forEach(scope.programs, function (program) {
                        if (program.readinessReview && program.readinessReview.reviewer && program.readinessReview.reviewer.personId == currentUser.profile.personId)
                            programsUnderReviewCount++;
                    });

                    //scope.cannotSwitchPrograms = (scope.rrMode && scope.isReviewer && programsUnderReviewCount < 2) || (!scope.rrMode && scope.isPevOrProgramObserver);
                    //Need to allow reviewer and pevs to access to multiple programs


                    scope.organizationId = scope.rrMode ?
                                               readinessSvc.data.selectedRR.organizationJson.organizationId :
                                               userReviewSvc.data.currentReview.organizationDto.currentOrganizationDetailDto.organizationId;

                    currentOrganizationHelper.loadDelegatedCommissions(scope.organizationId).then(function (data) {
                        currentOrganization.save();

                        if (scope.rrMode) {
                            scope.formattedOrganizationName = formatOrganizationName(readinessSvc.data.selectedRR.organizationJson.organizationName);
                            scope.commissionIds = readinessSvc.data.selectedRR.commissionIds;
                            if (!Array.isArray(scope.commissionIds)) scope.commissionIds = [scope.commissionIds];
                            scope.isPrimaryContact = scope.commissionIds.some(function (commissionId) {
                                currentUserHelper.isPrimary(scope.organizationId, commissionId);
                            });
                            scope.isDelegateForPrimary = scope.commissionIds.some(function (commissionId) {
                                currentUserHelper.isDelegateForPrimary(scope.organizationId, commissionId);
                            });
                            scope.isSecondary = scope.commissionIds.some(function (commissionId) {
                                currentUserHelper.isSecondary(scope.organizationId, commissionId);
                            });
                            scope.hasSubmitPermission = scope.isAdmin || scope.isPrimaryContact || scope.isDelegateForPrimary;
                        } else {
                            var organizationName = userReviewSvc.data.currentReview.organizationDto.currentOrganizationDetailDto.organizationName;
                            scope.formattedOrganizationName = formatOrganizationName(organizationName);
                            scope.commissionId = userReviewSvc.data.currentReviewTeam.commissionId;
                            scope.isPrimaryContact = currentUserHelper.isPrimary(scope.organizationId, scope.commissionId);
                            scope.isDelegateForPrimary = currentUserHelper.isDelegateForPrimary(scope.organizationId, scope.commissionId);
                            scope.isSecondary = currentUserHelper.isSecondary(scope.organizationId, scope.commissionId);
                            scope.isPresident = currentUserHelper.isPresident(scope.organizationId);
                            scope.isDelegateForPresident = currentUserHelper.isDelegateForPresident(scope.organizationId);
                            scope.isProvost = currentUserHelper.isProvost(scope.organizationId);
                            scope.hasSubmitPermission = scope.isAdmin || scope.isPrimaryContact || scope.isDelegateForPrimary;
                        }

                        if (scope.programs && scope.programs.length > 0) {
                            if (scope.rrMode) {
                                if (scope.isReviewer) {
                                    //select program that they are a reviewer for
                                    var selectedProgram = scope.programs.find(function (program) {
                                        return program.readinessReview.reviewer && program.readinessReview.reviewer.personId == currentUser.profile.personId;
                                    });

                                    scope.selectProgram(selectedProgram);
                                } else {
                                    scope.selectProgram(scope.programs[0]);
                                }
                                finishLoading();
                            } else {
                                var programReviews = programReviewSvc.data.programs;
                                scope.reviewTeamId = programReviews[0].reviewTeamId;
                                var currentPersonId = parseInt(currentUser.profile.personId);
                                if (!scope.isAdmin && !scope.isPevOrProgramObserver && !scope.isTeamChair && !scope.isEditor && !scope.isAdjunct) {
                                    scope.isReadOnly = userReviewSvc.data.currentReviewTeam.selfStudySubmittedTimestamp != null;
                                    scope.institutionView = !scope.isReadOnly;
                                    if (!scope.isPrimaryContact && !scope.isDelegateForPrimary && !scope.isSecondary && !scope.isPresident && !scope.isDelegateForPresident && !scope.isProvost) {
                                        // User is not primary contact, delegate of primary, president/provost/delegate thereof or admin; 
                                        // they should only see programs for which they are the program contact
                                        var listedPrograms = [];
                                        var unlistedPrograms = [];
                                        angular.forEach(scope.programs, function (program) {
                                            if (currentUserHelper.isProgramContact(program))
                                                listedPrograms.push(program);
                                            else
                                                unlistedPrograms.push(program);
                                        });
                                        scope.unlistedPrograms = unlistedPrograms; // needed later to validate if ready for submission
                                        scope.programs = listedPrograms;
                                    }
                                    scope.selectProgram(scope.programs[0]);
                                    finishLoading();
                                } else {
                                    // Check if current user is assigned to current review's review team.
                                    reviewTeamMemberSvc.getMyReviewTeamMembers(scope.reviewTeamId).then(function (members) {
                                        var isAssigned = members.some(function (member) {
                                            return member.personId === currentPersonId;
                                        });
                                        scope.isReadOnly = isAssigned;
                                        scope.institutionView = !scope.isReadOnly;
                                        //if pev, select the progam in which they are assigned
                                        if (scope.isPevOrProgramObserver) {
                                            // DAA shouldn't just select first program in the list... Let's find the first program that isn't locked
                                            // and belongs to user. 

                                            var pevMembers = userReviewSvc.getTeamPrograms();
                                            var selectedProgram = scope.programs.find(function (program) {
                                                var programReview = programReviewSvc.findProgramReview(program.programReviewId);
                                                return (programReview.selfStudyConfirmedByTC && pevMembers.some(function (member) {
                                                    return member.programId == program.programId && member.personId == currentPersonId;
                                                }));
                                            });

                                            // Couldn't find a program belonging to PEV and unlocked... find any program that is unlocked. 
                                            if (!selectedProgram) {
                                                selectedProgram = scope.programs.find(function (program) {
                                                    var programReview = programReviewSvc.findProgramReview(program.programReviewId);
                                                    return (programReview.selfStudyConfirmedByTC);
                                                });
                                            }

                                            if (selectedProgram) {
                                                scope.selectProgram(selectedProgram);
                                                confirmSelfStudyIsReviewed();
                                            } else {
                                                // if all programs locked, display no access message.
                                                scope.hasAccess = false;
                                                // Self-study-wide flag to complement program-wide hasAccess flag
                                                scope.lockedOut = true;
                                                scope.cannotSwitchPrograms = true;
                                                // Need program name to display even when all programs are locked
                                                if (scope.programs.length == 1) {
                                                    scope.activeProgramFullName = scope.getFullProgramName(scope.programs[0]);
                                                }
                                            }
                                        } else {
                                            scope.selectProgram(scope.programs[0]);
                                            confirmSelfStudyIsReviewed();
                                        }
                                        finishLoading();
                                    });
                                }
                            }
                        } else {
                            finishLoading();
                        }
                    });


                    function confirmSelfStudyIsReviewed() {
                        var isAdmin = currentUser.profile.userTasks.some(function (task) { return task == 'admin'; });

                        if (!isAdmin) {
                            var reviewTeamId = programReviewSvc.data.programs[0].reviewTeamId;
                            reviewTeamMemberSvc.getMyReviewTeamMembers(reviewTeamId).then(function (data) {
                                var reviewTeamMembers = data;
                                var currentReviewTeamMember = reviewTeamMembers.find(function (member) {
                                    return member.volunteerId === parseInt(currentUser.profile.volunteerId);
                                });
                                if (!scope.isEditor && !scope.isAdjunct && currentReviewTeamMember && !currentReviewTeamMember.hasReviewedOss) {
                                    var promptText = "Accessing the Self Study is confirmation that you will have a conflict of interest with this institution for any future reviews."
                                    var proceedFunc = function () {
                                        currentReviewTeamMember.hasReviewedOss = true;
                                        reviewTeamMemberSvc.updateTeamMemberReviewedOssStatus(currentReviewTeamMember).then(function () {
                                            alertSvc.addAlertSuccess("Sucessfully confirmed review of OSS.");
                                        })
                                    };
                                    var cancelFunc = function () {
                                        $state.go($state.current.name, { view: userReviewSvc.slugs.TEAM });
                                    };
                                    var isStrict = true;
                                    alertSvc.confirm(promptText, proceedFunc, null, isStrict, cancelFunc);
                                }
                            });
                        }
                    }

                    function finishLoading () {
                        scope.computeProgress();
                        scope.setSubmissionDetails();
                        scope.$watch('programs', scope.computeProgress, true);
                        scope.isDataReady = true;
                    }

                }();
            }
        };
    });

    module.directive('ossDragDropUpload', function ($timeout, Upload, alertSvc, documentSvc, readinessSvc, programReviewSvc, readinessValidationSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'ossDragDropUpload.html',
            scope: {
                criterion: '=',
                file: '=',
                title: '<?',
                multipleFiles: '@?',
                optional: '@?',
                referenceId: '@',
                readiness: '@?',
                disableDelete: '@?'
            },
            link: function (scope, elem, attrs) {
                scope.currentlyUploading = false;
                scope.isOptional = attrs.optional ? (attrs.optional.toLowerCase() == 'true') : false;
                scope.allowMultipleFiles = attrs.multipleFiles ? (attrs.multipleFiles.toLowerCase() == 'true') : false;
                scope.rrMode = attrs.readiness ? (attrs.readiness.toLowerCase() == 'true') : false;
                scope.disableDelete = attrs.disableDelete ? (attrs.disableDelete.toLowerCase() == 'true') : false;

                //09242020: allow readiness to submit optional appendix. 
                //scope.isDisabled = scope.isOptional && scope.rrMode;

                scope.singleDocumentOption = scope.criterion.criteriaTypeId === 11;

                scope.accept = scope.singleDocumentOption ? readinessSvc.SELFSTUDYACCEPTEDMIMETYPES : readinessSvc.SELFSTUDYDEFAULTMIMETYPE;
                scope.pattern = "'" + scope.accept + "'";

                // Disable drag-and-drop on main window to prevent users from accidentally opening instead of dragging files.
                documentSvc.disableDragAndDropOnParent();

                // Hide HTML title tooltip
                elem[0].title = '';

                scope.getTitle = function () {
                    return scope.title || readinessSvc.getCriteriaFullName(scope.criterion);
                }

                scope.upload = function (file, invalidFiles) {
                    scope.currentlyUploading = true;

                    if (file) {
                        if (!scope.allowMultipleFiles && scope.criterion.stream_id) {
                            // Replace existing file
                            var replaceMsg = "You are about to replace <em><strong>" + scope.criterion.originalFileName + "</strong></em> with <em><strong>" + file.name + "</strong></em>. Do you want to proceed?";
                            alertSvc.confirm(replaceMsg, function () {
                                documentSvc.deleteFile(scope.criterion.stream_id).then(function () {
                                    doUpload(file);
                                })
                            });
                        } else {
                            doUpload(file);
                        }
                    } else if (invalidFiles && invalidFiles.length > 0) {
                        // Uploaded unacceptable file type
                        var invalidFileMessage = scope.singleDocumentOption ?
                            "Only PDF, Word, and Excel documents and Zip file archives are accepted for upload. Please convert your document or add to a Zip file and try again." :
                            "Only PDFs are accepted for upload. Please save your document as a PDF and try again.";
                        alertSvc.openModalAlert(invalidFileMessage, "Wrong File Type");
                        scope.currentlyUploading = false;
                    }

                    function doUpload(file) {
                        documentSvc.uploadFile(file).then(
                            function (response) {
                                scope.$evalAsync(function ($scope) {
                                    if (scope.allowMultipleFiles) {
                                        scope.criterion.files = scope.criterion.files || [];
                                        scope.criterion.files.push({
                                            originalFileName: file.name,
                                            stream_id: response.data.value
                                        });
                                    } else {
                                        scope.criterion.stream_id = response.data.value;
                                        scope.criterion.originalFileName = file.name;
                                    }
                                    if (scope.rrMode) {
                                        readinessSvc.update(readinessSvc.data.selectedRR, true).then(
                                            function () {
                                                readinessValidationSvc.invokeValidation();
                                                alertSvc.addAlertSuccess("Document successfully uploaded.");
                                                scope.currentlyUploading = false;
                                            },
                                            function () {
                                                alertSvc.addAlertWarning("Document could not be uploaded at this time.");
                                                scope.currentlyUploading = false;
                                            });
                                    } else {
                                        // Update program review with new/updated self-study criteria.
                                        var programReview = programReviewSvc.findProgramReview(scope.criterion.programReviewId);
                                        var selfStudyCriteria = programReview.selfStudyCriteriaDtos;
                                        var selfStudyCriterion;
                                        // Find existing self-study criterion object.
                                        if (!scope.allowMultipleFiles) {
                                            selfStudyCriterion = selfStudyCriteria.find(function (criterion) {
                                                return criterion.criteriaId === scope.criterion.criteriaId;
                                            });
                                        }
                                        // Create new criterion object if not found or if multiple uploads are allowed.
                                        if (!selfStudyCriterion || (scope.allowMultipleFiles && selfStudyCriterion.stream_id != null)) {
                                            selfStudyCriterion = {
                                                selfStudyCriteriaId: 0,
                                                programReviewId: programReview.programReviewId,
                                                criteriaId: scope.criterion.criteriaId
                                            };
                                            selfStudyCriteria.push(selfStudyCriterion);
                                        }
                                        // Set uploaded file information.
                                        selfStudyCriterion.originalFileName = file.name;
                                        selfStudyCriterion.stream_id = response.data.value;
                                        // Save program review.
                                        programReviewSvc.updateOdata(programReview).then(
                                            function () {
                                                readinessValidationSvc.invokeValidation();
                                                alertSvc.addAlertSuccess("Document successfully uploaded.");
                                                scope.currentlyUploading = false;
                                            },
                                            function () {
                                                alertSvc.addAlertWarning("Document could not be uploaded at this time.");
                                                scope.currentlyUploading = false;
                                            });
                                    }
                                });
                            },
                            function (response) {
                                alertSvc.addModalAlertWarning("Document could not be uploaded at this time.");
                                scope.currentlyUploading = false;
                            }
                        );
                    }
                };

                scope.deleteFile = function () {
                    if (!scope.allowMultipleFiles) {
                        alertSvc.confirmDelete(scope.file.name, function () {
                            scope.file = null;
                        })
                    }
                };

                scope.delete = function (file) {
                    if (scope.disableDelete) return;

                    var deleteMsg = "You are about to remove <em><strong>" + file.originalFileName + "</strong></em>  Do you want to proceed?";
                    alertSvc.confirm(deleteMsg, function () {
                        doDelete(file.stream_id);
                    });

                    function doDelete(stream_id) {
                        documentSvc.deleteFile(stream_id).then(
                            function () {
                                if (scope.allowMultipleFiles && Array.isArray(scope.criterion.files)) {
                                    var index = scope.criterion.files.findIndex(function (item) { return item.stream_id === stream_id; });
                                    scope.criterion.files.splice(index, 1);
                                } else {
                                    scope.criterion.stream_id = null;
                                    scope.criterion.originalFileName = null;
                                }
                                if (scope.rrMode) {
                                    readinessSvc.update(readinessSvc.data.selectedRR, true).then(
                                        function () {
                                            readinessValidationSvc.invokeValidation();
                                            alertSvc.addAlertSuccess("Document successfully removed.");
                                        },
                                        function () {
                                            alertSvc.addAlertWarning("Document could not be removed at this time.");
                                        });
                                } else {
                                    // Save program review with deleted document removed form criteria
                                    var programReview = programReviewSvc.findProgramReview(scope.criterion.programReviewId);
                                    var selfStudyCriteria = programReview.selfStudyCriteriaDtos;
                                    var selfStudyCriterion = selfStudyCriteria.find(function (criterion) {
                                        return criterion.stream_id === stream_id;
                                    });
                                    if (selfStudyCriterion) {
                                        if (selfStudyCriterion.criteriaId == programReviewSvc.SELFSTUDYCRITERIAID ||
                                            selfStudyCriterion.criteriaId == programReviewSvc.BACKGROUNDCRITERIAID) {
                                            selfStudyCriterion.originalFileName = null;
                                            selfStudyCriterion.stream_id = null;
                                        } else {
                                            selfStudyCriterion.isDeleted = true;
                                        }
                                    };
                                    programReviewSvc.updateOdata(programReview).then(
                                        function () {
                                            readinessValidationSvc.invokeValidation();
                                            alertSvc.addAlertSuccess("Document successfully removed.");
                                        },
                                        function () {
                                            alertSvc.addAlertWarning("Document could not be removed at this time.");
                                        });
                                }
                            },
                            function () {
                                alertSvc.addModalAlertWarning("Document could not be removed at this time.");
                            });
                    }
                };

                scope.hasFiles = function (criterion) {
                    //return ((criterion !== undefined && criterion !== null && criterion.stream_id !== undefined && criterion.stream_id !== null) || (Array.isArray(criterion.files) && criterion.files.length > 0));
                    return (scope.allowMultipleFiles && Array.isArray(criterion.files) && criterion.files.length > 0) || (criterion && criterion.stream_id);
                }
            }
        }
    });

}(angular.module('oss')));