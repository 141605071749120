(function (module) {
    'use strict';

    var rfrLogicSvc = function (programReviewTypeIds) {
        var factory = {};
        var minPevs = 2;

        var checkForMinNumber = function (numberOfPevs) {
            if (numberOfPevs < minPevs) {
                return minPevs;
            }
            return numberOfPevs;
        }

        factory.getSingleProgramPevNumber = function (program, isSingleProgram, simultaneousOption, jointProgram) {
            // Termination requests get 0 PEVs
            if (program.programReviewTypeCode === null)
                return 0;
            if (program.programReviewTypeCode === programReviewTypeIds.FOCUSEDREPORT)
                return 0;
            if (program.programReviewTypeCode === programReviewTypeIds.TERMINATIONREPORT)
                return 0;
            if (program.programReviewTypeCode === programReviewTypeIds.TERMINATIONVISIT)
                return 0;

            if (isSingleProgram) {
                if (simultaneousOption) {
                    if (program.programReviewTypeCode === programReviewTypeIds.COMPREHENSIVEVISIT)
                        return 1;
                    else
                        return 2;
                }
                if (jointProgram) {
                    if (program.programReviewTypeCode === programReviewTypeIds.COMPREHENSIVEVISIT)
                        return 1;
                    else
                        return 2;
                }
                if (program.degreeCode === 'AS') {
                    return 1;
                } else if (program.programReviewTypeCode === programReviewTypeIds.COMPREHENSIVEVISIT) {//program seeking reacreditation
                    return 1;
                }
                else
                    return minPevs;
            }
            else {
                return 1;
            }
        }

       

        //Pass in the commission jsonContents
        factory.getNumberOfPevsByCommission = function(rfrCommissionJson) {
            var numberOfPevs = 0;
            var programs = rfrCommissionJson.programs;

            for (var i = 0; i < programs.length; i++) {
                var program = programs[i];

                for (var j = 0; j < program.disciplines.length; j++) {
                    var discipline = program.disciplines[j];
                    numberOfPevs += discipline.pevsRequired;
                }
            }

            return numberOfPevs;
        }



        return {
            getNumberOfPevsByCommission: factory.getNumberOfPevsByCommission,
            getSingleProgramPevNumber: factory.getSingleProgramPevNumber

        };
    };

    module.factory('rfrLogicSvc', rfrLogicSvc);

})(angular.module('common'));