(function (module) {
    var ossCtrl = function ($stateParams, programReviewSvc, helperSvc, readinessSvc, userReviewSvc, criteriaTypes, programReviewTypeIds,
                            readinessValidationSvc, reviewTeamMemberSvc, selfStudyUploadOptionTypes) {
        var model = this;
        model.isDataReady = false;
        model.reviewTeamId = parseInt($stateParams.reviewTeamId);
      
        var selfStudyCriteriaTypes = [
            criteriaTypes.SELFSTUDY,
            criteriaTypes.BACKGROUND,
            criteriaTypes.CRITERIA,
            criteriaTypes.INTERIMREPORT,
            criteriaTypes.TERMINATIONPLAN,
            criteriaTypes.MASTERSGENERALCRITERIA,
            criteriaTypes.APPENDIX,
            criteriaTypes.TRANSCRIPT,
            criteriaTypes.OPTIONAL,
            criteriaTypes.ADDITIONAL
        ];

        var reportProgramReviewTypes = [
            programReviewTypeIds.BODFOCUSED,
            programReviewTypeIds.FOCUSEDREPORT,
            programReviewTypeIds.FOCUSEDVISIT,
            programReviewTypeIds.FOCUSEDSHOWCAUSE,
            programReviewTypeIds.SHOWCAUSEREPORT,
            programReviewTypeIds.SHOWCAUSEVISIT,
            programReviewTypeIds.EXCOMFOCUSEDREPORT,
            programReviewTypeIds.EXCOMFOCUSEDVISIT,
        ];

        var terminationProgramReviewTypes = [
            programReviewTypeIds.TERMINATIONREPORT,
            programReviewTypeIds.TERMINATIONVISIT,
        ];

        var selfStudyCriteriaTypesForIR = [
            criteriaTypes.INTERIMREPORT,
            criteriaTypes.OPTIONAL,
            criteriaTypes.ADDITIONAL
        ];

        var selfStudyCriteriaTypesForTermination = [
            criteriaTypes.TERMINATIONPLAN,
            criteriaTypes.OPTIONAL,
            criteriaTypes.ADDITIONAL
        ];

        var selfStudyCriteriaTypesForOSSSingleDocument = [
            criteriaTypes.SELFSTUDY,
            criteriaTypes.OPTIONAL,
            criteriaTypes.ADDITIONAL
        ];

        var selfStudyCriteriaTypesForOSS = [
            criteriaTypes.BACKGROUND,
            criteriaTypes.CRITERIA,
            criteriaTypes.APPENDIX,
            criteriaTypes.OPTIONAL,
            criteriaTypes.ADDITIONAL
        ];

        var selfStudyCriteriaTypesForIntegratedMS = [
            criteriaTypes.BACKGROUND,
            criteriaTypes.CRITERIA,
            criteriaTypes.MASTERSGENERALCRITERIA,
            criteriaTypes.APPENDIX,
            criteriaTypes.OPTIONAL,
            criteriaTypes.ADDITIONAL
        ];

        var selfStudyCriteriaTypesForMS = [
            criteriaTypes.BACKGROUND,
            criteriaTypes.MASTERSGENERALCRITERIA,
            criteriaTypes.APPENDIX,
            criteriaTypes.OPTIONAL,
            criteriaTypes.ADDITIONAL
        ];

        function convertProgramReviewsToPrograms(programReviews, criteria) {
            var programs = [];

            // Transform each program review into a readiness review compatible program object.
            angular.forEach(programReviews, function (programReview) {
                // Create "readiness review" program object to populate self-study directive with

                if (programReview.actionCode == 'W' || programReview.actionCode == 'C'){
                        return;
                }
                    
                var program = {
                    programName: programReview.programDto.programDetailDto.programName,
                    commissionIds: [programReview.programDto.commissionId],
                    degreeCode: programReview.programDto.programDetailDto.degreeCode,
                    degreeLevelCode: programReview.programDto.programDetailDto.degreeLevelCode,
                    isMasterDegreeIntegrated: programReview.programDto.programDetailDto.degreeLevelCode,
                    readinessReview: {
                        criteria: []
                    },
                    programReviewId: programReview.programReviewId,
                    programId: programReview.programId
                };
                programs.push(program);
                // Transform self study criteria into "readiness review" criteria.
                var uploadOptionType = programReviewSvc.getUploadOptionType(programReview);
                var includedCriteriaTypes;
                if (reportProgramReviewTypes.indexOf(programReview.programReviewTypeCode) > -1) {
                    includedCriteriaTypes = selfStudyCriteriaTypesForIR;
                } else if (terminationProgramReviewTypes.indexOf(programReview.programReviewTypeCode) > -1) {
                    includedCriteriaTypes = selfStudyCriteriaTypesForTermination;
                } else if (uploadOptionType == selfStudyUploadOptionTypes.UNINITIALIZED) {
                    // If uninitialized (i.e. user hasn't selected single or multi upload), leave program readiness review criteria empty.
                    // Note: already checked for IR/TR and initialized program readiness review criteria for them because user has no choice but single upload for report.
                    return;
                } else if (uploadOptionType == selfStudyUploadOptionTypes.SINGLEDOCUMENT) {
                    includedCriteriaTypes = selfStudyCriteriaTypesForOSSSingleDocument;
                    // Per Hwan 2018-04-24, ignore degreeLevelCode and treat as Masters program only if isMasterDegreeIntegrated is not null
                } else if (programReview.programDto.programDetailDto.degreeLevelCode === 'M' &&
                           programReview.programDto.programDetailDto.isMasterDegreeIntegrated != null) {
                    includedCriteriaTypes = programReview.programDto.programDetailDto.isMasterDegreeIntegrated ?
                                                selfStudyCriteriaTypesForIntegratedMS :
                                                selfStudyCriteriaTypesForMS;
                } else {
                    includedCriteriaTypes = selfStudyCriteriaTypesForOSS;
                }

                angular.forEach(criteria, function (criterion) {
                    // Show different criteria for IR reviews than for other types.
                    if (includedCriteriaTypes.indexOf(criterion.criteriaTypeId) < 0) return;
                    var readinessReviewCriteria = {
                        programReviewId: programReview.programReviewId,
                        criteriaId: criterion.criteriaId,
                        criteriaTypeId: criterion.criteriaTypeId,
                        criteriaName: criterion.criteriaName,
                        criteriaDescription: criterion.criteriaDescription,
                        readinessRequired: criterion.readinessRequired,
                    };
                    // Populare file information with uploaded file information from self-study criteria
                    var selfStudyCriteria = programReview.selfStudyCriteriaDtos.filter(function (selfStudyCriteria) {
                        return selfStudyCriteria.criteriaId === criterion.criteriaId;
                    });
                    if (readinessReviewCriteria.criteriaTypeId === criteriaTypes.ADDITIONAL ||
                        readinessReviewCriteria.criteriaTypeId === criteriaTypes.OPTIONAL) {
                        // Criterion allows multiple files.
                        readinessReviewCriteria.files = selfStudyCriteria.map(function (selfStudyCriterion) {
                            return {
                                originalFileName: selfStudyCriterion.originalFileName,
                                stream_id: selfStudyCriterion.stream_id
                            };
                        });
                    } else {
                        // Criterion accepts only one file.
                        var selfStudyCriterion = selfStudyCriteria[0] || null;
                        readinessReviewCriteria.originalFileName = selfStudyCriterion ? selfStudyCriterion.originalFileName : null;
                        readinessReviewCriteria.stream_id = selfStudyCriterion ? selfStudyCriterion.stream_id : null;
                    }
                    program.readinessReview.criteria.push(readinessReviewCriteria);
                });
            });

            return programs;
        }
        
        function findCommissionCriteria(allCommissionCriteria, commissionIds, degreeLevelCode) {
            var isMastersProgram = degreeLevelCode === 'M';
            var commissionCriteria = allCommissionCriteria.filter(function (commissionCriterion) {
                return isCriterionMatch(commissionCriterion, commissionIds, degreeLevelCode);
            });

            return commissionCriteria;
        }

        function findCommissionCriterion(allCommissionCriteria, commissionIds, degreeLevelCode) {
            var commissionCriterion = allCommissionCriteria.find(function (commissionCriterion) {
                return isCriterionMatch(commissionCriterion, commissionIds, degreeLevelCode);
            });

            return commissionCriterion;
        }

        function isCriterionMatch(commissionCriterion, commissionIds, degreeLevelCode) {
            var isMastersProgram = degreeLevelCode === 'M';
            var isMatch = false;

            if (commissionIds.length > 1) {
                isMatch = commissionCriterion.jointCommissionId != null &&
                          commissionIds.indexOf(commissionCriterion.jointCommissionId) > -1 &&
                          commissionIds.indexOf(commissionCriterion.commissionId) > -1;
            } else {
                isMatch = commissionCriterion.jointCommissionId == null &&
                          commissionCriterion.commissionId == commissionIds[0];
            }

            var isCriterionMasterLevel = commissionCriterion.isMasterLevel == null ? false : commissionCriterion.isMasterLevel;
            isMatch = isMatch && (isCriterionMasterLevel == isMastersProgram);

            return isMatch;
        }


        function findProgramCommissions(programReview) {
            var programCommissionId = programReview.programDto.commissionId;
            var commissionIds = [programCommissionId];
            // See if this program is involved in any join reviews.
            var reviewJointOption = userReviewSvc.data.currentReview.reviewJointOptionDtos.find(function (reviewJointOption) {
                return reviewJointOption.reviewJointOptionProgramDtos.some(function (reviewJointOptionProgram) {
                    return reviewJointOptionProgram.programId === programReview.programId;
                });
            });
            if (reviewJointOption != null) {
                // Add commissions from other programs involved in joint reviews to list.
                angular.forEach(reviewJointOption.reviewJointOptionProgramDtos, function (reviewJointOptionProgram) {
                    if (reviewJointOptionProgram.programId != programReview.programId) {
                        commissionIds.push(reviewJointOptionProgram.commissionId);
                    }
                });
            }

            return commissionIds;
        }

        var activate = function () {
            readinessSvc.getCriteria(selfStudyCriteriaTypes).then(function (criteria) {
                programReviewSvc.loadProgramReviewsByReviewTeamId(model.reviewTeamId).then(function () {
                    var programReviews = programReviewSvc.data.programs;
                    initializeProgramReviewSelfStudyCriteria(programReviews);
                    model.programs = convertProgramReviewsToPrograms(programReviews, criteria);
                    var isPev = userReviewSvc.isPevOrProgramObserver(userReviewSvc.data.currentUserReviewTeam);
                    var personId = userReviewSvc.data.currentUserReviewTeam.personId;
                    if (isPev) {
                        model.programs.forEach(function (program) {
                            var programReview = programReviewSvc.findProgramReview(program.programReviewId);
                            program.locked = !(programReview.selfStudyConfirmedByTC);
                        });
                    }
                    
                    readinessValidationSvc.invokeValidation();
                    model.isDataReady = true;
                });
            });
        }();

        function initializeProgramReviewSelfStudyCriteria(programReviews) {
            angular.forEach(programReviews, function (programReview) {
                programReview.selfStudyCriteriaDtos = Array.isArray(programReview.selfStudyCriteriaDtos) ? programReview.selfStudyCriteriaDtos : [];
            });
        }
    };
    module.controller('ossCtrl', ossCtrl);

}(angular.module('userReview')));