(function (module) {

    var travelAvailabilityEditCtrl = function (parent, $scope, $uibModalInstance, $state, alertSvc, volunteerSvc, codeSvc, travelAvailabilitySvc, volunteerHelperSvc, helperSvc) {
        var model = $scope;
        var checkIsVolunteer = true;

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.currentInfo = {};
        model.volunteerId = volunteerHelperSvc.getVolunteerId(); 

        model.currentInfo.travelAvailability = travelAvailabilitySvc.data.travelAvailability.volunteerAvailabilityTravelId ? angular.copy(travelAvailabilitySvc.data.travelAvailability) :
            {
                volunteerId: model.volunteerId,
                year: travelAvailabilitySvc.currentYear,
                volunteerTravelAvailability: null,   
                volunteerVirtualAvailability: null,
                willFulfilABETRequirement: null,
                willFulfilInstitutionRequirement: null
            };

        model.save = function () {

            //if travel answer is NO
            if (!model.currentInfo.travelAvailability.volunteerTravelAvailability || model.currentInfo.travelAvailability.volunteerTravelAvailability == 4) {
                model.currentInfo.travelAvailability.willFulfilABETRequirement = null;
                model.currentInfo.travelAvailability.willFulfilInstitutionRequirement = null;
            }

            if (model.currentInfo.travelAvailability.volunteerAvailabilityTravelId) {
                travelAvailabilitySvc.updateTravelAvailability(model.currentInfo.travelAvailability).then(onSuccess, onFailure);
            }
            else {
                travelAvailabilitySvc.createTravelAvailability(model.currentInfo.travelAvailability).then(onSuccess, onFailure);
            }

            function onSuccess() {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Travel Availability information successfully saved.");
            }
            function onFailure() {
                alertSvc.addModalAlertWarning('Travel Availability  information could not be saved at this time.');
            }

        };

        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('travelAvailabilityEditCtrl', travelAvailabilityEditCtrl);

}(angular.module('volunteer')));